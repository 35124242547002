define("webclient/components/place-autocomplete-field", ["exports", "ember-place-autocomplete/components/place-autocomplete-field"], function (_exports, _placeAutocompleteField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _placeAutocompleteField.default;
    }
  });
});
