import { isEmpty } from "@ember/utils";
import BasicRoute from "webclient/routes/basic";

export default BasicRoute.extend({
  titleToken(model) {
    return model.get("name");
  },
  model(params) {
    return this.store
      .loadRecords("shop", {
        "filter[slug]": params.shop_slug,
        "filter[visible]": true,
        include: "addresses,images,image-main"
      })
      .then(shops => {
        if (isEmpty(shops)) {
          return this.transitionTo("/not-found");
        } else {
          return shops.get("firstObject");
        }
      });
  },
  afterModel(model) {
    let ogTags = {
      title: model.name,
      description: model.descr,
      image: model.mainImage.get("url")
    };
    this.headTags.registerOgTags(ogTags);
  },
  serialize(model) {
    return {
      shop_slug: model.get("slug")
    };
  }
});
