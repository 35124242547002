export default function validateZipcode(store, translation) {
  return (key, newValue) => {
    if (newValue && newValue.length !== 5) {
      return translation.t(
        "checkout_address.address_form.postal_code.error.length"
      );
    }
    return store
      .query("shippingZipCode", {
        filter: {
          zipCode: newValue
        }
      })
      .then(function(zipCodes) {
        return zipCodes.length
          ? true
          : translation.t(
              "checkout_address.address_form.postal_code.error.no_shipping"
            );
      });
  };
}
