import Component from "@ember/component";
import { inject as service } from "@ember/service";
import { hash } from "rsvp";
import { assert } from "@ember/debug";

export default Component.extend({
  stripe: service("stripev3"),
  stripeElement: null,
  isLoading: false,
  stripeErrorMessage: null,
  router: service(),
  store: service(),
  intl: service(),

  init() {
    this._super(...arguments);
    assert(
      "An `updateTaxAmount` action is required when calling the `checkout-payment` component",
      typeof this.updateTaxAmount === "function"
    );
  },

  actions: {
    submit(stripeElement) {
      this.set("stripeErrorMessage", null);
      if (stripeElement) {
        this.set("isLoading", true);
        const stripe = this.stripe;
        let stripeSourceId;
        const order = this.order;
        return hash({
          token: stripe.createToken(stripeElement)
        })
          .then(({ token }) => {
            stripeSourceId = token;
            return this.store
              .createRecord("stripe-payment", {
                order: order,
                stripeSourceId: stripeSourceId.token.id
              })
              .save()
              .catch(error => {
                if (error && error.errors[0] && error.errors[0].detail) {
                  this.set(
                    "stripeErrorMessage",
                    error.errors[0].detail.capitalize()
                  );
                }
                throw error;
              });
          })
          .then(() => {
            window.location = this.router.urlFor("receipt", order.id);
          })
          .catch(() => {
            this.set("isLoading", false);
          });
      } else {
        this.set("isLoading", false);
      }
    }
  }
});
