import { alias, readOnly, reads } from "@ember/object/computed";
import DS from "ember-data";
import { computed } from "@ember/object";

export default DS.Model.extend({
  priceGrossCents: DS.attr("number"),
  priceGross: alias("priceGrossCents"),
  priceNet: DS.attr("number"),
  vat: DS.attr("number"),
  payableCents: DS.attr("number"),
  payable: alias("payableCents"),
  countCustomer: DS.attr("number"),
  productIterationId: DS.attr("string"),
  priceNetCents: DS.attr("number"),
  priceNetCurrency: DS.attr("string"),

  product: alias("productVariant.product"),

  productPrice: readOnly("product.priceNet"),
  onSale: readOnly("product.onSale"),

  order: DS.belongsTo("order", { inverse: "items" }),
  productVariant: DS.belongsTo("productVariant", { async: true }),
  shippingSku: DS.belongsTo("shippingSku", { async: true }),
  shop: DS.belongsTo("shop", { async: true }),

  pipName: DS.attr("string"),
  pipImageMain: DS.belongsTo("image", { async: true }),

  totalPrice: reads("payable"),

  productItem: computed("productVariant.id", function() {
    return !!this.belongsTo("productVariant").id();
  }),

  shippingItem: computed("shippingSku.id", function() {
    return !!this.belongsTo("shippingSku").id();
  }),

  shopId: alias("shop.id")
});
