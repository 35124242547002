import { alias } from "@ember/object/computed";
import Component from "@ember/component";
import { computed, get } from "@ember/object";

export default Component.extend({
  user: null,
  title: alias("user.name"),
  attributeBindings: ["style"],
  classNames: ["user-avatar"],

  noAvatar: computed("user.avatarUrl", function() {
    const url = get(this, "user.avatarUrl");
    return !url;
  }),

  style: computed("user.avatarUrl", function() {
    let url = get(this, "user.avatarUrl");

    if (!url) {
      // placeholder will be loaded in the template
      return;
    }

    return `background-image:url(${url});`.htmlSafe();
  })
});
