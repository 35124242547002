import { helper } from "@ember/component/helper";

/**
 * Truncates text to the specified length. Adds an ellipsis to the end.
 * If there was nothing to shorten, nothing is added.
 *
 * ```hbs
 * {{truncate "Lorem ipsum dolor" length=8}}
 * ```
 *
 * The above prints out "Lorem ipsum…".
 *
 * If you only want to truncate a single line, you might want to truncate
 * the text using CSS instead. There's a `truncate` tailwind-class for this
 * exact purpose.
 */
export default helper(function truncate([text], { length }) {
  if (!text) {
    return text;
  }

  let short = text.substring(0, length);
  if (text.length === short.length) {
    return text;
  }
  return `${short.trim()}…`;
});
