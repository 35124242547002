import Route from "@ember/routing/route";

export default Route.extend({
  redirect(model, transition) {
    let url = this.router.location.formatURL("/not-found");
    if (window.location.pathname !== url) {
      transition.abort();
      return this.intermediateTransitionTo("not-found");
    }
  }
});
