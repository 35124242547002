import { run } from "@ember/runloop";
import { isPresent } from "@ember/utils";
import { merge } from "@ember/polyfills";
import RSVP from "rsvp";
import CustomOAuth2Authenticator from "webclient/authenticators/custom-oauth2";

export default CustomOAuth2Authenticator.extend({
  authenticate(token) {
    return new RSVP.Promise((resolve, reject) => {
      // jscs:disable requireCamelCaseOrUpperCaseIdentifiers
      let data = {
        grant_type: "refresh_token",
        refresh_token: token
      };

      this.makeRequest(this.serverTokenEndpoint, data).then(
        response => {
          run(this, function() {
            let expires_at = this.absolutizeExpirationTime(response.expires_in);
            this.scheduleAccessTokenRefresh(
              response.expires_in,
              expires_at,
              response.refresh_token
            );
            if (isPresent(expires_at)) {
              response = merge(response, { expires_at });
            }
            resolve(response);
          });
        },
        xhr => {
          run(function() {
            reject(xhr.responseJSON || xhr.responseText);
          });
        }
      );
    });
  }
});
