import Component from "@ember/component";
import { reads } from "@ember/object/computed";

export default Component.extend({
  supportsDataTestProperties: true,
  "data-test-loading-id": "",
  tagName: "",
  type: "submit",
  loading: false,
  disabled: reads("loading"),
  action: () => {},
  didClickLadda() {
    this.action();
  }
});
