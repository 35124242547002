import Component from "@ember/component";
import { computed } from "@ember/object";
import classic from "ember-classic-decorator";

@classic
class ImageTag extends Component {
  image = null;
  options = null;
  crop = null;
  sizes = null;
  width = undefined;
  height = undefined;

  @computed("crop")
  get fit() {
    if (this.crop === null) {
      return null;
    }
    return "crop";
  }

  @computed("width", "image.{url,thumbnailUrl}")
  get localUrl() {
    if (typeof this.width === "undefined" || this.width > 256) {
      return this.image.get("url");
    } else {
      return this.image.get("thumbnailUrl");
    }
  }

  @computed("width", "height", "options.ar")
  get aspectRatioClass() {
    if (this.options && this.options.ar === "16:9") {
      return "image--cover";
    } else if (
      typeof this.width !== "undefined" &&
      this.width === this.height
    ) {
      return "image--square";
    } else {
      return null;
    }
  }

  @computed("aspectRatioClass")
  get innerClass() {
    if (this.get("aspectRatioClass") === null) {
      return null;
    } else {
      return "image__inner";
    }
  }
}

export default ImageTag;
