import Component from "@ember/component";

export default Component.extend({
  classNames: ["content-overlay"],

  click() {
    // eslint-disable-next-line ember/closure-actions
    this.sendAction();
    return false;
  },

  touchStart() {
    // eslint-disable-next-line ember/closure-actions
    this.sendAction();
    return false;
  }
});
