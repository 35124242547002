import Controller from "@ember/controller";
import { action } from "@ember/object";
import classic from "ember-classic-decorator";

@classic
class ProductsController extends Controller {
  @action
  search(query) {
    this.transitionToRoute("search", { queryParams: { q: query } });
  }
}

export default ProductsController;
