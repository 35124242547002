import Component from "@ember/component";

export default Component.extend({
  tagName: "",
  "data-test-id": "",
  supportsDataTestProperties: true,
  type: "text",
  disabled: false,
  oninput(e) {
    this.set("value", e.target.value);
  }
});
