import DS from "ember-data";

export default DS.Model.extend({
  name: DS.attr("string"),
  sortOrder: DS.attr("number"),
  productCount: DS.attr("number"),
  visible: DS.attr("boolean"),

  parent: DS.belongsTo("category", { inverse: "children", async: true }),
  children: DS.hasMany("category", { inverse: "parent", async: false }),
  products: DS.hasMany("product", { async: true, inverse: "category" }),
  image: DS.belongsTo("image")
});
