import { schedule } from "@ember/runloop";
import RootRoute from "webclient/routes/root";
import { inject as service } from "@ember/service";

export default RootRoute.extend({
  intercom: service(),

  beforeModel() {
    let session = this.session;
    if (session.isAuthenticated) {
      this.intercom.set("user", null);
      schedule("render", () => {
        session.invalidate();
      });
    } else {
      return this.replaceWith("application");
    }
  }
});
