import ApplicationSerializer from "./application";

export default ApplicationSerializer.extend({
  serializeBelongsTo(snapshot, json, { key }) {
    switch (key) {
      case "openOrder":
        return;
      default:
        this._super(...arguments);
    }
  },
  serializeHasMany(snapshot, json, { key }) {
    switch (key) {
      default:
        this._super(...arguments);
    }
  }
});
