import Component from "@ember/component";

export default Component.extend({
  classNames: ["lightbox-image"],
  isShowingModal: false,
  image: null,
  status: null,
  type: null,
  crop: null,
  width: undefined,
  height: undefined,
  options: null
});
