import Component from "@ember/component";
import { computed } from "@ember/object";

export default Component.extend({
  groups: computed(
    "order.items.@each.{shopId,productItem,shippingItem}",
    function() {
      let items = this.get("order.items");
      let shops = items.mapBy("shop").uniqBy("id");

      return shops.map(function(shop) {
        let shopItems = items.filter(function(item) {
          return item.belongsTo("shop").id() == shop.get("id");
        });

        return {
          shop: shop,
          productItems: shopItems.filterBy("productItem"),
          shippingItems: shopItems.filterBy("shippingItem")
        };
      });
    }
  )
});
