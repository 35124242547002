import { helper as buildHelper } from "@ember/component/helper";
import { htmlSafe } from "@ember/template";

export function commaSplit(value) {
  if (value) {
    value = value.replace(/(?:,\s?)/g, "<br>");
    return new htmlSafe(value);
  } else {
    return value;
  }
}

export default buildHelper(commaSplit);
