import Component from "@ember/component";

export default Component.extend({
  supportsDataTestProperties: true,
  tagName: "",
  type: "button",
  disabled: false,
  class: "",
  onClick: () => {}
});
