import { computed, get } from "@ember/object";
import DS from "ember-data";

export function promiseMapBy(collection, property) {
  // eslint-disable-next-line ember/require-computed-property-dependencies
  return computed(`${collection}.@each.${property}`, function() {
    return DS.PromiseArray.create({
      promise: get(this, collection).then(collection =>
        collection.mapBy(property)
      )
    });
  });
}

export function promiseFilterBy(collection, property, value) {
  // eslint-disable-next-line ember/require-computed-property-dependencies
  return computed(`${collection}.@each.${property}`, function() {
    return DS.PromiseArray.create({
      promise: get(this, collection).then(collection =>
        collection.filterBy(property, value)
      )
    });
  });
}
