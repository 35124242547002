import { mapBy, uniq } from "@ember/object/computed";
import Component from "@ember/component";
import { computed, get } from "@ember/object";
import { isEmpty } from "@ember/utils";
import { intersection } from "../utils/array-helpers";
import { inject as service } from "@ember/service";

export default Component.extend({
  product: null,
  variants: null,
  properties: null,
  hintText: null,
  selectedVariant: null,
  noVariantSelected: null,
  cart: service(),
  propertyChanged: () => {},

  propertyKeys: mapBy("properties", "key"),
  uniquePropertyKeys: uniq("propertyKeys"),

  selectObjects: computed("uniquePropertyKeys.[]", "properties.[]", function() {
    let keys = get(this, "uniquePropertyKeys");
    let properties = get(this, "properties");
    if (isEmpty(properties)) {
      return;
    }

    let sizes = [
      "XXXS",
      "XXS",
      "XS",
      "S",
      "M",
      "L",
      "XL",
      "XXL",
      "XXXL",
      "XXXXL"
    ];

    // For each key, make an object with a label and its values
    return keys.map(function(key) {
      // Find the values that match this key.
      let values = properties.filterBy("key", key).sortBy("value");

      let mappedValues = values.mapBy("value");

      // Sort sizes and move them to the top if found
      // TODO: This can probably be improved very much by some
      // functional programming magic.
      let sizeValues = intersection(sizes, mappedValues);
      if (sizeValues.length > 0) {
        let sortedValues = [];
        sizes.forEach(function(size) {
          if (mappedValues.includes(size)) {
            sortedValues.push(values.findBy("value", size));
            mappedValues.removeObject(size);
          }
        });
        mappedValues.forEach(function(value) {
          sortedValues.push(values.findBy("value", value));
        });
        values = sortedValues;
      }
      return { values, label: key };
    });
  }),

  actions: {
    valueChanged(property) {
      this.propertyChanged(property);
    },

    addToCart(variant) {
      this.cart.addToCart.perform(variant);
    }
  }
});
