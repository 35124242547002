import { inject as service } from "@ember/service";
import AuthenticatedRouteMixin from "ember-simple-auth/mixins/authenticated-route-mixin";
import BasicRoute from "webclient/routes/basic";

export default BasicRoute.extend(AuthenticatedRouteMixin, {
  account: service(),

  beforeModel(transition) {
    this._super(transition);
    this.store.peekAll("order").forEach(order => {
      if (!order.get("isSaving")) {
        order.unloadRecord();
      }
    });
    this.store.unloadAll("orderItem");
    this.store.unloadAll("cartItem");
  },

  model() {
    return this.store.findAll("cart-item", {
      include: "product-variant.product,product-variant.properties",
      reload: true
    });
  },

  actions: {
    checkout() {
      return this.transitionTo("checkout").catch(error => {
        if (error && error.errors[0] && error.errors[0].detail) {
          this.controller.set(
            "checkoutErrorMessage",
            error.errors[0].detail.capitalize()
          );
          this.replaceWith("cart");
        }
      });
    },

    checkoutAnyway() {
      let checkoutController = this.get("controller.checkoutController");
      checkoutController.set("bypassOverbooked", true);
      return this.transitionTo("checkout").catch(error => {
        if (error && error.errors[0] && error.errors[0].detail) {
          this.controller.set(
            "checkoutErrorMessage",
            error.errors[0].detail.capitalize()
          );
          this.replaceWith("cart");
        }
      });
    }
  }
});
