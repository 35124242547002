import ApplicationSerializer from "./application";

export default ApplicationSerializer.extend({
  serializeAttribute(snapshot, json, key) {
    if (key !== "priceNetCents") {
      this._super(...arguments);
    }
  },

  serializeBelongsTo(snapshot, json, { key }) {
    if (key === "orderItem") {
      return;
    }

    this._super(...arguments);
  }
});
