import DS from "ember-data";
import { bool } from "@ember/object/computed";

export default DS.Model.extend({
  name: DS.attr("string"),
  resultType: DS.attr("string"),
  sortOrder: DS.attr("number"),

  category: DS.belongsTo("category", { async: false }),
  product: DS.belongsTo("product", { async: false }),
  shop: DS.belongsTo("shop", { async: false }),

  isCategory: bool("category.id"),
  isProduct: bool("product.id"),
  isShop: bool("shop.id")
});
