import DS from "ember-data";
import config from "webclient/config/environment";
import DataAdapterMixin from "ember-simple-auth/mixins/data-adapter-mixin";
import { isPresent } from "@ember/utils";
import { inject as service } from "@ember/service";

export default DS.JSONAPIAdapter.extend(DataAdapterMixin, {
  session: service("session"),
  authorize(xhr) {
    let { access_token } = this.get("session.data.authenticated");
    if (isPresent(access_token)) {
      xhr.setRequestHeader("Authorization", `Bearer ${access_token}`);
    }
  },

  namespace: config.namespace,
  host: config.host,
  coalesceFindRequests: true,
  shouldBackgroundReloadRecord: () => false,
  shouldReloadAll: () => false
});
