import Component from "@ember/component";
import { task } from "ember-concurrency";
import { inject as service } from "@ember/service";
import { assert } from "@ember/debug";
import ENV from "../config/environment";

export default Component.extend({
  googleAddress: null,
  intl: service(),
  account: service(),
  store: service(),
  googlePlaceAutocompleteService: service("google-place-autocomplete"),
  googleApiKey: null,
  postalCodeError: null,
  selectedUserAddress: null,
  userAddresses: null,
  isShowingAddressList: false,
  addressToUpdate: null,
  isPlaceEditable: null,

  init() {
    this._super(...arguments);

    assert(
      "An `updateTaxAmount` action is required when calling the `checkout-address` component",
      typeof this.updateTaxAmount === "function"
    );

    this.googleApiKey = ENV["place-autocomplete"]
      ? ENV["place-autocomplete"].key
      : null;
    if (this.changeset.get("addressLine")) {
      this.set("googleAddress", this.changeset.get("addressLine"));
    }
    if (this.changeset.get("state")) {
      this.findSubregion.perform(this.changeset.get("state"));
    }
  },
  changeset: null,

  checkValidAndSaveModels: task(function*(performTransition) {
    this.changeset.set("state", null);
    if (this.selectedState) {
      this.changeset.set("state", this.get("selectedState.name"));
    }
    if (this.googleAddress) {
      this.changeset.set("addressLine", this.googleAddress);
    } else {
      this.changeset.set("addressLine", this.changeset.get("googleAddress"));
    }

    yield this.changeset.validate();
    yield this.zipcodeChangeset.validate();

    if (this.zipcodeChangeset.error.postalCode) {
      this.send("validatePostalCode");
    }

    if (
      this.changeset.get("isInvalid") ||
      this.zipcodeChangeset.get("isInvalid")
    ) {
      return;
    }

    var address = null;
    if (this.addressToUpdate) {
      const addressToUpdate = this.addressToUpdate;
      addressToUpdate.setProperties({
        firstName: this.changeset.get("firstName"),
        lastName: this.changeset.get("lastName"),
        addressLine: this.changeset.get("addressLine"),
        postalCode: this.zipcodeChangeset.get("postalCode"),
        city: this.changeset.get("city"),
        state: this.changeset.get("state")
      });
      address = yield addressToUpdate.save();
    } else {
      this.changeset.set("postalCode", this.zipcodeChangeset.get("postalCode"));
      address = yield this.changeset.save();
    }

    const order = this.model;

    order.setProperties({
      invoiceAddress: address,
      deliveryAddress: address
    });

    yield order.save();

    if (this.changeset.change.user) {
      this.account.user.setProperties({
        cellphone: this.changeset.get("user.cellphone"),
        email: this.changeset.get("user.email")
      });
      yield this.account.user.save();
    }

    performTransition();

    this.updateTaxAmount(true);
    this.set("addressToUpdate", null);
  }).drop(),

  loadUserAddresses: task(function*() {
    this.set("isPlaceEditable", false);
    this.changeset.rollback();
    let userAddresses = yield this.account.user.userAddresses;
    if (userAddresses.length > 1) {
      this.set("isShowingAddressList", true);
      this.send("selectAddress", userAddresses.firstObject);
    } else {
      this.set("isShowingAddressList", false);
    }
    this.set("userAddresses", userAddresses);
  }).on("didInsertElement"),

  saveUserAddress: task(function*(performTransition) {
    const address = this.selectedUserAddress;
    this.changeset.set("state", address.state);

    yield this.changeset.validate();

    if (this.changeset.get("isInvalid")) {
      return;
    }

    const order = this.model;
    order.setProperties({
      invoiceAddress: address,
      deliveryAddress: address
    });
    yield order.save();

    if (this.changeset.change.user) {
      this.account.user.setProperties({
        cellphone: this.changeset.get("user.cellphone"),
        email: this.changeset.get("user.email")
      });
      yield this.account.user.save();
    }

    performTransition();

    this.updateTaxAmount(true);

    this.set("selectedUserAddress", null);
  }).drop(),

  loadSubregions: task(function*(search) {
    let subregions = yield this.store.query("subregion", {
      filter: { search: search }
    });
    if (!subregions.length) {
      this.changeset.pushErrors(
        "state",
        this.intl.t("checkout_address.address_form.state.error.invalid", {
          state: search.capitalize()
        })
      );
    }
    return subregions;
  }),

  findSubregion: task(function*(search) {
    this.set("selectedState", null);
    let subregions = yield this.store.query("subregion", {
      filter: { search: search }
    });
    if (subregions.length) {
      this.set("selectedState", subregions.firstObject);
    }
    this.changeset.set("state", this.get("selectedState.name"));
  }),

  getPlaceDetails: task(function*(postalCode) {
    let geocoder = new this.googlePlaceAutocompleteService.google.maps.Geocoder();
    let result = yield new Promise(function(resolve) {
      geocoder.geocode(
        {
          address: postalCode
        },
        function(results, status) {
          let state = null;
          let city = null;
          if (status === "OK" && results.firstObject) {
            results.firstObject.address_components.forEach(
              address_component => {
                if (
                  address_component.long_name &&
                  address_component.types.includes(
                    "administrative_area_level_1"
                  )
                ) {
                  state = address_component.long_name;
                } else if (
                  address_component.long_name &&
                  address_component.types.includes("locality")
                ) {
                  city = address_component.long_name;
                }
              }
            );
          }
          resolve({ city, state });
        }
      );
    });
    this.changeset.set("city", result.city);
    this.findSubregion.perform(result.state);
  }),

  actions: {
    checkPlaceChanged(place) {
      let streetAddress = null;
      let postalCode = null;
      let state = null;
      let city = null;
      this.set("isPlaceEditable", false);
      place.address_components.forEach(address_component => {
        if (
          address_component.long_name &&
          address_component.types.includes("street_number")
        ) {
          streetAddress = address_component.long_name;
        } else if (
          address_component.long_name &&
          address_component.types.includes("route")
        ) {
          streetAddress = streetAddress
            ? streetAddress + " " + address_component.long_name
            : address_component.long_name;
        } else if (
          address_component.long_name &&
          address_component.types.includes("postal_code")
        ) {
          postalCode = address_component.long_name;
        } else if (
          address_component.long_name &&
          address_component.types.includes("administrative_area_level_1")
        ) {
          state = address_component.long_name;
        } else if (
          address_component.long_name &&
          address_component.types.includes("locality")
        ) {
          city = address_component.long_name;
        }
      });
      this.zipcodeChangeset.set("postalCode", postalCode);
      this.changeset.set("city", city);
      this.send("validatePostalCode");
      this.set("googleAddress", streetAddress);
      this.findSubregion.perform(state);
    },
    selectAddress(userAddress) {
      this.set("selectedUserAddress", userAddress);
      this.zipcodeChangeset.set("postalCode", userAddress.postalCode);
    },
    updateUserAddress(userAddress) {
      this.set("addressToUpdate", userAddress);
      this.set("isShowingAddressList", false);
      this.findSubregion.perform(userAddress.state);
      this.changeset.set("firstName", userAddress.firstName);
      this.changeset.set("lastName", userAddress.lastName);
      if (this.googleApiKey) {
        this.set("googleAddress", userAddress.addressLine);
      } else {
        this.changeset.set("googleAddress", userAddress.addressLine);
      }
      this.zipcodeChangeset.set("postalCode", userAddress.postalCode);
      this.changeset.set("city", userAddress.city);
    },
    addNewAddress() {
      this.setUpChangeset();
      this.set("isShowingAddressList", false);
      this.set("selectedState", null);
      this.set("googleAddress", null);
    },
    searchSubregion(search) {
      this.set("selectedState", null);
      return this.loadSubregions.perform(search);
    },
    validatePostalCode() {
      this.set("postalCodeError", null);
      this.zipcodeChangeset.validate().then(() => {
        if (this.zipcodeChangeset.error.postalCode) {
          this.set("postalCodeError", this.zipcodeChangeset.error.postalCode);
        }
      });
    },
    handleKeydown(select, e) {
      // TAB
      if (e && e.keyCode === 9) {
        select.actions.select(select.highlighted, e);
      }
      // BACKSPACE
      if (e && e.keyCode === 8) {
        this.send("subregionChanged", null);
      }
    },
    zipValueChanged() {
      this.set("postalCodeError", null);
      if (this.zipcodeChangeset.get("postalCode").length === 5) {
        this.send("validatePostalCode");
        this.getPlaceDetails.perform(this.zipcodeChangeset.get("postalCode"));
      }
    },
    subregionChanged(subregion) {
      this.set("selectedState", subregion);
      this.changeset.set("state", this.get("selectedState.name"));
    }
  }
});
