import { sort } from "@ember/object/computed";
import { computed } from "@ember/object";
import Controller from "@ember/controller";

export default Controller.extend({
  hasProducts: computed("model.products.[]", function() {
    return this.get("model.products.length") > 0;
  }),

  productSort: Object.freeze(["publishedAt:desc"]),
  sortedProducts: sort("model.products", "productSort")
});
