import Route from "@ember/routing/route";

export default Route.extend({
  afterModel(shop) {
    return this.store.query("shopBrand", {
      "filter[shopId]": shop.get("id"),
      include: "brand"
    });
  }
});
