import Component from "@ember/component";

export default Component.extend({
  image: null,
  isShowingModal: false,
  options: null,
  init(...args) {
    this._super(...args);
    this.set("options", {
      "max-h": 300,
      "max-w": 1024,
      ar: "16:9"
    });
  }
});
