import Component from "@ember/component";
import { inject } from "@ember/service";

export default Component.extend({
  store: inject(),
  initialQuery: "", // Set by caller, usually from query params
  query: "",
  search: () => {},

  init() {
    this._super(...arguments);
    if (this.initialQuery) {
      this.set("query", this.initialQuery);
      this.set("selected", { name: this.query });
    }
  },

  actions: {
    select(result) {
      if (result !== null && result !== undefined) {
        this.set("query", result.name);
      }
      this.search(this.query);
      this.set("selected", { name: this.query });
    },

    typeaheadSearch(search) {
      this.set("query", search);
      return this.store
        .query("search-result", {
          filter: { query: search }
        })
        .then(result => {
          let array = result.toArray();
          array.unshiftObject({ name: search });
          return array;
        });
    },

    onKeydown(select, event) {
      if (event.keyCode === 13) {
        this.send("select", select.highlighted);
        select.actions.close();
        return false;
      }
    }
  }
});
