import Component from "@ember/component";
import { inject as service } from "@ember/service";
import { task } from "ember-concurrency";

export default Component.extend({
  store: service(),
  intl: service(),
  isDeliver: false,
  zipCodeError: null,
  availabilityStatus: null,

  checkIfDeliverToAddress: task(function*(zipCode) {
    this.set("zipCodeError", null);
    this.set("isDeliver", false);
    if (zipCode && zipCode.length === 5) {
      let shippingZipCode = yield this.store.query("shipping-zip-code", {
        filter: { zipCode: zipCode }
      });
      if (shippingZipCode.length) {
        this.set("isDeliver", true);
        this.set(
          "availabilityStatus",
          this.intl.t("zip_code_form.willDeliverMessage", {
            zipCode: zipCode
          })
        );
      } else {
        this.set(
          "availabilityStatus",
          this.intl.t("zip_code_form.willNotDeliverMessage", {
            zipCode: zipCode
          })
        );
      }
    } else {
      this.set("zipCodeError", this.intl.t("zip_code_form.invalid_zip_code"));
    }
  })
});
