import Controller from "@ember/controller";
import { action, computed } from "@ember/object";

export default Controller.extend({
  queryParams: ["q"],
  q: null,
  initialQuery: "",
  results: null,
  isSearching: false,

  groupedResults: computed("results.[]", function() {
    if (this.results === null) {
      return null;
    }
    return {
      categories: this.results
        .filter(result => result.isCategory)
        .map(result => result.category),
      products: this.results
        .filter(result => result.isProduct)
        .map(result => result.product),
      shops: this.results
        .filter(result => result.isShop)
        .map(result => result.shop)
    };
  }),

  emptyResult: computed("isSearching", "results.[]", function() {
    // Null results is no search yet
    if (this.isSearching || this.results === null) {
      return false;
    }

    if (this.results === null || this.results.length) {
      return false;
    } else {
      return true;
    }
  }),

  search: action(function(query) {
    this.set("isSearching", true);
    this.store.unloadAll("search-result");

    this.set("q", query);

    this.store
      .query("search-result", {
        filter: { query: query },
        include:
          "product.shop,product.image-main,category.image,shop.image-main,product.brand"
      })
      .then(results => {
        this.set("results", results);
        this.set("isSearching", false);
      });
  })
});
