import RootRoute from "webclient/routes/root";
import classic from "ember-classic-decorator";

@classic
class LandingpageRoute extends RootRoute {
  beforeModel() {
    super.beforeModel(...arguments);
    this.controllerFor("application").set("noHeader", true);
  }
}

export default LandingpageRoute;
