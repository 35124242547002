import { computed } from "@ember/object";
import { alias, readOnly, oneWay } from "@ember/object/computed";
import DS from "ember-data";

export default DS.Model.extend({
  priceNetCents: DS.attr("number"),
  priceNet: alias("priceNetCents"),
  count: DS.attr("number"),
  productIterationId: DS.attr("string"),
  onSale: readOnly("product.onSale"),
  productPrice: readOnly("product.priceNet"),

  user: DS.belongsTo("user"),
  productVariant: DS.belongsTo("productVariant", {
    async: true,
    inverse: "cartItem"
  }),
  orderItem: DS.belongsTo("orderItem", { async: false }),

  product: alias("productVariant.product"),
  productVariantId: alias("productVariant.id"),
  localInventory: oneWay("productVariant.localInventory"),
  shop: alias("productVariant.product.shop"),

  soldOutCount: computed("localInventory", "count", function() {
    let inventory = this.localInventory;
    let count = this.count;
    if (inventory >= 0) {
      inventory = 0;
    }
    return count - (count + inventory);
  }),

  availableCount: computed("count", "soldOutCount", function() {
    return this.count - this.soldOutCount;
  }),

  increment() {
    this.incrementProperty("count");
  },

  decrement() {
    let count = this.count;
    if (count > 0) {
      this.decrementProperty("count");
    }
    return this.save();
  }
});
