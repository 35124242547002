import Controller from "@ember/controller";
import { inject as service } from "@ember/service";

export default Controller.extend({
  identification: null,
  password: null,
  errorMessage: null,
  intl: service(),
  session: service(),
  resetPasswordURL:
    window.location.protocol +
    "//" +
    window.location.host +
    "/backend/users/password/new",

  actions: {
    authenticate(e) {
      e.preventDefault();
      this.session
        .authenticate(
          "authenticator:custom-oauth2",
          this.identification,
          this.password,
          ["default", "user"]
        )
        .catch(reason => {
          let message = reason.error || reason;
          if (message === "invalid_grant") {
            message = this.intl.t("login.invalid_grant_error");
          }
          this.set("errorMessage", message);
        });
    }
  }
});
