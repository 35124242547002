import { gt } from "@ember/object/computed";
import DS from "ember-data";
import {
  promiseMapBy,
  promiseFilterBy
} from "webclient/utils/promise-computeds";

export default DS.Model.extend({
  name: DS.attr("string"),
  descr: DS.attr("string"),
  url: DS.attr("string"),
  hidden: DS.attr("boolean"),

  image: DS.belongsTo("image"),

  shopBrands: DS.hasMany("shopBrand", { async: true }),
  products: DS.hasMany("product", { async: true }),

  shops: promiseMapBy("shopBrands", "shop"),
  visibleShops: promiseFilterBy("shops", "visible", true),

  hasProducts: gt("products.length", 0),
  visible: gt("visibleShops.length", 0)
});
