import Component from "@ember/component";
import { debounce } from "@ember/runloop";

export default Component.extend({
  isSearching: false,
  runSearch: function() {
    this.send("handleFilterEntry", this.value);
  },

  init() {
    this._super(...arguments);
    this.filter("").then(results => this.set("results", results));
  },

  actions: {
    handleFilterEntry() {
      this.set("isSearching", true);
      this.filter(this.value).then(filterResults => {
        this.set("results", filterResults);
        this.set("isSearching", false);
      });
    },
    handleChange(newValue) {
      this.set("value", newValue);
      debounce(this, this.runSearch, 350);
    }
  }
});
