import Application from "@ember/application";
import Resolver from "./resolver";
import loadInitializers from "ember-load-initializers";
import config from "./config/environment";
import moment from "moment";

// Ember.run.backburner.DEBUG = true; // Super useful for getting stack traces from previous runloop parts.

const App = Application.extend({
  modulePrefix: config.modulePrefix,
  podModulePrefix: config.podModulePrefix,
  Resolver
});

// App.set('appId', config.fbAppID);
config.isLocalDevelopment =
  window.location.hostname.indexOf("localhost") !== -1;
config.isInProduction = [
  "organicshopping.com",
  "beta.organicshopping.com"
].includes(window.location.hostname);
config.isInStaging = ["testing.organicshopping.com"].includes(
  window.location.hostname
);
config.twoDaysAgo = moment().subtract(2, "days");
if (config.isInProduction) {
  config.releaseStage = "production";
} else if (config.isInStaging) {
  config.releaseStage = "staging";
} else {
  config.releaseStage = "development";
}

config["bugsnag-reporter"].releaseStage = config.releaseStage;

if (config.runningOnNow && !config.isInProduction && !config.isInStaging) {
  // We are running in a detached now deployment
  config.host = "https://staging-backend.organicshopping.com";
}

if (config.isInProduction) {
  config.intercom.appId = "qm2d8m38";
  config.stripe.publishableKey = "pk_live_KwvyNBkaxURkDUPJUrjGyZMZ";
} else {
  config.intercom.appId = "pvsg4n6l";
  config.stripe.publishableKey = "pk_test_kZ2PQhi9mdHG7ilJh3Fj3cv5";
}

loadInitializers(App, config.modulePrefix);

export default App;
