import { later } from "@ember/runloop";
import $ from "jquery";
import EmberObject from "@ember/object";
import { computed } from "@ember/object";
import Evented from "@ember/object/evented";
import Controller from "@ember/controller";
import { inject as service } from "@ember/service";

export default Controller.extend(Evented, {
  menuIsOpen: false,
  isAccountOpen: false,
  account: service(),
  store: service(),

  facebookParams: EmberObject.create({
    version: "v2.3"
  }),

  // Disabling rule here because there's no way to declare a dependency to
  // the store like we were hoping for.
  // eslint-disable-next-line ember/require-computed-property-dependencies
  showFarmList: computed(function() {
    return this.store.peekAll("shop").filterBy("showInList", true).length > 0;
  }),

  menuStateChanged() {
    if (this.menuIsOpen) {
      // eslint-disable-next-line ember/no-jquery
      $(".page-container").addClass("noscroll");
      // eslint-disable-next-line ember/no-jquery
      $("body").addClass("noscroll");
    } else {
      // Wait until menu is back in place
      later(
        this,
        function() {
          // eslint-disable-next-line ember/no-jquery
          $(".page-container").removeClass("noscroll");
          // eslint-disable-next-line ember/no-jquery
          $("body").removeClass("noscroll");
        },
        500
      );
    }
  }
});
