import Component from "@ember/component";
import { computed, get } from "@ember/object";

export default Component.extend({
  variant: null,

  addToCart: () => {},

  disabled: computed("variant.unavailable", function() {
    let variant = get(this, "variant");

    if (!variant) {
      return true;
    } else {
      return get(variant, "unavailable");
    }
  })
});
