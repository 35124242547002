import { alias, sort } from "@ember/object/computed";
import Controller from "@ember/controller";

export default Controller.extend({
  shopSort: Object.freeze(["name"]),
  brand: alias("model"),
  sortedShops: sort("brand.visibleShops", "shopSort"),

  productSort: Object.freeze(["publishedAt:desc"]),
  sortedProducts: sort("brand.products", "productSort")
});
