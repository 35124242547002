import DS from "ember-data";

export default DS.Model.extend({
  begins: DS.attr("date"),
  ends: DS.attr("date"),
  customerVisible: DS.attr("boolean"),

  label: DS.attr("string"),
  title: DS.attr("string"),
  descr: DS.attr("string"),

  discountedPercent: DS.attr("number"),
  discountedPrice: DS.attr("number"),

  shop: DS.belongsTo("shop")
});
