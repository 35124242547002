import { schedule } from "@ember/runloop";
import RootRoute from "webclient/routes/root";

export default RootRoute.extend({
  model(params) {
    // Log in with refresh token
    schedule("render", () => {
      // this.get('session').authenticate('authenticator:refresh-token', params.refresh_token);
      this.session.authenticate("authenticator:liar").then(() => {
        if (typeof params.path !== "undefined" && params.path !== null) {
          this.replaceWith("/") + params.path;
        } else {
          this.replaceWith("/");
        }
      });
    });
  }
});
