import {
  validatePresence,
  validateFormat
} from "ember-changeset-validations/validators/index";

export default (store, translation) => {
  return {
    state: [
      validatePresence({
        presence: true,
        message: translation.t(
          "checkout_address.address_form.state.error.required"
        )
      })
    ],
    "user.cellphone": [
      validatePresence({
        presence: true,
        message: translation.t("checkout_address.phone.error.required")
      })
    ],
    "user.email": [
      validatePresence({
        presence: true,
        message: translation.t("checkout_address.email.error.required")
      }),
      validateFormat({
        type: "email",
        message: translation.t("checkout_address.email.error.format")
      })
    ]
  };
};
