import { inject as service } from "@ember/service";
import Controller from "@ember/controller";
import WelcomeValidations from "../validations/welcome";

export default Controller.extend({
  WelcomeValidations: WelcomeValidations,
  saving: false,
  logger: service(),
  account: service(),

  actions: {
    save(changeset) {
      this.set("saving", true);
      let account = this.account;
      let user = this.model;

      return changeset
        .validate()
        .then(() => {
          if (!changeset.get("isValid")) {
            return;
          }

          // Track successful registration
          this.logger.finishedWelcome(user);

          return changeset
            .save()
            .then(() => {
              return account.get("settings").setBool("welcomeScreenDone", true);
            })
            .then(() => {
              this.send("completedWelcomeScreen");
            })
            .catch(() => {
              this.get("model.errors").forEach(({ attribute, message }) => {
                changeset.pushErrors(attribute, message);
              });
            });
        })
        .finally(() => {
          this.set("saving", false);
        });
    }
  }
});
