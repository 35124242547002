import DS from "ember-data";

export default DS.Model.extend({
  sumCents: DS.attr("number"),
  sumCurrency: DS.attr("string"),
  payer: DS.belongsTo("user"),
  recipient: DS.belongsTo("user"),
  order: DS.belongsTo("order"),
  status: DS.attr("string"),
  stripeChargeId: DS.attr("string"),
  stripeSourceId: DS.attr("string")
});
