import Service, { inject as service } from "@ember/service";
import { computed, get } from "@ember/object";
import { task, timeout } from "ember-concurrency";

export default Service.extend({
  init() {
    this._super(...arguments);
    this.cartItems = [];
  },

  account: service(),
  store: service(),
  notifications: service(),
  session: service(),
  logger: service(),
  router: service(),

  cartItems: null,
  DEBOUNCE_MS: 250,

  // Calculations
  cartItemsCount: computed("cartItems.@each.count", function() {
    return this.cartItems.mapBy("count").reduce((prev, current) => {
      return prev + current || 0;
    }, 0);
  }),

  cartTotal: computed("cartItems.@each.{priceNet,count}", function() {
    let total = 0;
    this.cartItems.forEach(item => {
      total += item.priceNet * item.count;
    });
    return total;
  }),

  // Add and remove items to and from cart
  reload() {
    return this._cartChanged();
  },

  addToCart: task(function*(variant) {
    if (this.get("session.isAuthenticated")) {
      if (variant && get(variant, "localInventory") > 0) {
        this.notifications.showNotification();
        yield this.addVariantToCart.perform(variant);
        this.logger.addedToCart();
      }
    } else {
      this.session.set("variantToCart", variant);
      this.router.transitionTo("login");
    }
  }),

  decrementCartItem(item) {
    let count = item.get("count");
    if (count <= 1) {
      return this.removeCartItem(item);
    } else {
      return item
        .decrement()
        .then(() => this._cartChanged())
        .then(() => item);
    }
  },

  removeCartItem(item) {
    if (!item.inFlight) {
      return item
        .destroyRecord()
        .then(() => this._cartChanged())
        .then(() => null);
    }
  },

  // @private
  _cartChanged() {
    return this.store.findAll("cart-item").then(cartItems => {
      return this.set("cartItems", cartItems);
    });
  },

  _createCartItem(variant) {
    return variant
      .get("product")
      .then(product => {
        if (product.get("iterationId")) {
          return product;
        } else {
          return product.reload();
        }
      })
      .then(product => {
        let attrs = {
          priceNet: product.get("priceNet"),
          count: 1,
          productIterationId: product.get("iterationId"),
          productVariant: variant,
          user: this.get("account.currentUser.user")
        };
        let cartItem = this.store.createRecord("cartItem", attrs);
        return cartItem;
      });
  },

  // @private
  findCartItemByProductVariant(productVariant) {
    return this.cartItems.findBy("productVariant", productVariant);
  },

  addPendingItemToCart: task(function*() {
    if (
      this.session.variantToCart &&
      this.session.variantToCart.localInventory > 0
    ) {
      this.notifications.showNotification();
      yield this.addToCart.perform(this.session.variantToCart);
      this.logger.addedToCart();
      this.session.set("variantToCart", null);
    }
  }),

  addVariantToCart: task(function*(variant) {
    let cartItem = yield variant.get("cartItem");
    if (typeof cartItem !== "undefined" && cartItem !== null) {
      yield cartItem.increment();
    } else {
      cartItem = yield this._createCartItem(variant);
    }
    yield timeout(this.DEBOUNCE_MS);
    yield cartItem.save();
  }).restartable()
});
