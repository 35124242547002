import Component from "@ember/component";
import { isEmpty } from "@ember/utils";
import { run } from "@ember/runloop";
import { computed, set, get } from "@ember/object";
import { guidFor } from "@ember/object/internals";
import $ from "jquery";

export default Component.extend({
  tagName: "img",
  attributeBindings: ["src", "alt"],
  classNames: ["address-map"],
  address: null,
  width: 750,
  height: 200,
  scale: 1,
  alt: "Kart",

  adaptedAddress: computed("address", function() {
    let streetAddress = get(this, "address");
    if (isEmpty(streetAddress)) {
      return "";
    }
    return `${get(
      streetAddress,
      "name"
    )}, ${get(streetAddress, "addressLine")}, ${get(streetAddress, "postalCode")}, ${get(streetAddress, "postalPlace")}`;
  }),

  src: computed("adaptedAddress", "width", "height", "scale", function() {
    let width = get(this, "width");
    let height = get(this, "height");
    let scale = get(this, "scale");
    let adaptedAddress = encodeURIComponent(get(this, "adaptedAddress"));

    let mapUrl = "//maps.googleapis.com/maps/api/staticmap?";
    mapUrl += `&zoom=15&size=${width}x${height}`;
    mapUrl += `&markers=color:0x000000|center:${adaptedAddress}`;
    mapUrl += "&style=saturation:-100";
    mapUrl += `&scale=${scale}`;
    mapUrl += "&key=AIzaSyDAtoEHzxEpNXwRUfRMnOmN6bMUlIVJtZI";
    return mapUrl;
  }),

  willDestroyElement() {
    // eslint-disable-next-line ember/no-jquery
    $(window).off(`resize.${guidFor(this)}`);
  },

  didInsertElement() {
    this.scheduleUpdatingSize();
    // eslint-disable-next-line ember/no-jquery
    $(window).on(
      `resize.${guidFor(this)}`,
      run.bind(this, this.scheduleUpdatingSize)
    );
  },

  scheduleUpdatingSize() {
    run.scheduleOnce("afterRender", this, this.updateSize);
  },

  updateSize() {
    if (this._state === "inDOM") {
      let scale = window.devicePixelRatio || 1;
      set(this, "scale", scale);
      // eslint-disable-next-line ember/no-jquery
      let reportedWidth = this.$().width();
      if (reportedWidth && reportedWidth > 0) {
        set(this, "width", reportedWidth);
      }
      // let reportedHeight = this.$().height();
      // if (reportedWidth && reportedHeight > 0) {
      //   set(this, 'height', reportedHeight);
      // }
    }
  },

  click() {
    window.open(
      `https://maps.google.com/?q=${get(this, "adaptedAddress")}`,
      "_blank"
    );
  }
});
