export function deleteLatestMapEntry(map) {
  let key = getLatestMapEntryKey(map);
  map.delete(key);
  return map;
}

export function getLatestMapEntryKey(map) {
  const { size } = map;
  let i = 0;
  let finalKey = null;

  // Maps are iterated over in insertion order
  map.forEach(function(value, key) {
    i++;
    if (size === i) {
      finalKey = key;
    }
  });
  return finalKey;
}
