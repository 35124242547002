/* eslint-disable no-console */
import Service, { inject as service } from "@ember/service";
import config from "webclient/config/environment";
import { scheduleOnce } from "@ember/runloop";

export default Service.extend({
  facebook: service("facebook-tracker"),
  intercom: service(),
  metrics: service(),

  logLogging(thingLogged, extraInfo) {
    if (config.LOG_LOGGING) {
      if (typeof extraInfo !== "undefined") {
        console.info(`Logging ${thingLogged}`, extraInfo);
      } else {
        console.info(`Logging ${thingLogged}`);
      }
    }
  },

  pageview() {
    return this.logLogging("pageview");
  },

  finishedWelcome(/* user */) {
    this.logLogging("finished welcome");
    return this.facebook.finishedWelcome();
  },

  addedToCart(productVariant) {
    this.logLogging("added to cart", productVariant);
    return this.facebook.addedToCart();
  },

  completedPurchase(order) {
    this.logLogging("completed purchase", order);
    return this.facebook.completedPurchase();
  },

  // Setup
  setUser(user) {
    let userObject = user.getProperties(
      "email",
      "name",
      "id",
      "unixCreatedAtUTC"
    );
    return this.intercom.set("user", userObject);
  },

  updateUser(user) {
    return this.intercom.set("user", user);
  },

  trackPage(page, title) {
    scheduleOnce("afterRender", this.metrics, this.metrics.trackPage, {
      page,
      title
    });
  },

  initializeMetrics() {
    let googleTagManagerParams = "";
    if (config.isInProduction) {
      googleTagManagerParams =
        "gtm_auth=ZySUHLK7aGluF6Wj0D-Iqw&gtm_preview=env-2&gtm_debug=x";
    } else {
      googleTagManagerParams =
        "gtm_auth=ljYEWCzHzXtgcRBNZukbzg&gtm_preview=env-5&gtm_debug=x&url=https://testing.organicshopping.com";
    }
    this.metrics.activateAdapters([
      {
        name: "GoogleTagManager",
        environments: ["production"],
        config: {
          id: "GTM-N6ZTR73",
          envParams: googleTagManagerParams
        }
      }
    ]);
  }
});
