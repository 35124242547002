import Service, { inject as service } from "@ember/service";

export default Service.extend({
  headData: service(),
  router: service(),
  registerOgTags({
    title = this.defaultTitle(),
    description = this.defaultDescription(),
    image = this.defaultImage()
  } = {}) {
    const ogTags = {
      title,
      description: this.generateMinifiedDescription(description),
      image
    };
    this.set("headData.ogTags", ogTags);
  },
  defaultTitle() {
    return "Organic Shopping";
  },
  defaultDescription() {
    return "We are an organic marketplace where people can shop organic foods, meat, and vegetables from local organic farms";
  },
  defaultImage() {
    return (
      window.location.protocol +
      "//" +
      window.location.host +
      "/assets/images/logo@2x.png"
    );
  },
  generateMinifiedDescription(description) {
    description = description || " ";
    return description.split(". ")[0] || " ";
  },
  resetOgTags() {
    this.set("headData.ogTags", {});
  }
});
