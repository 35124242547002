import DS from "ember-data";
import { computed } from "@ember/object";

export default DS.Model.extend({
  key: DS.attr("string"),
  value: DS.attr("string"),
  keyedValue: computed("key", "value", function() {
    return `${this.key}: ${this.value}`;
  }),

  productVariants: DS.hasMany("productVariant", {
    inverse: "properties",
    async: true
  })
});
