import { helper as buildHelper } from "@ember/component/helper";
import { get } from "@ember/object";

/*
 * Takes an array and an index and checks if the index is the last element
 * in the array.
 */
export function isLast([array, index]) {
  return get(array, "length") - 1 === index;
}

export default buildHelper(isLast);
