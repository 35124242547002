/*
|-----------------------------------------------------------------------------
| Opacity                                https://tailwindcss.com/docs/opacity
|-----------------------------------------------------------------------------
|
| Here is where you define your opacity utility values. By default we
| provide a sensible numeric scale. You can, of course, modify these
| values as needed.
|
| Class name: .opacity-{name}
|
*/

export default {
  "0": "0",
  "25": ".25",
  "50": ".5",
  "75": ".75",
  "100": "1"
};
