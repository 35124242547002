import { computed } from "@ember/object";
import { mapBy, sum, gt, equal } from "@ember/object/computed";
import { inject as service } from "@ember/service";
import Controller, { inject as controller } from "@ember/controller";
import { isPresent } from "@ember/utils";

export default Controller.extend({
  intl: service(),
  checkoutErrorMessage: null,

  // This is accessed from the cart route. Horrible.
  checkoutController: controller("checkout"),
  cart: service(),
  account: service(),

  itemCounts: mapBy("model", "availableCount"),
  cartItemsCount: sum("itemCounts"),
  someSoldOut: gt("soldOutCount", 0),

  soldOutText: computed("intl.locale", "soldOutCount", function() {
    return this.intl.t("cart.n_not_available", { count: this.soldOutCount });
  }),

  soldOutCount: computed("model.@each.soldOutCount", function() {
    let count = 0;
    this.model.forEach(item => {
      count += item.get("soldOutCount");
    });
    return count;
  }),

  numProducts: computed("intl.locale", "cartItemsCount", function() {
    return this.intl.t("cart.n_products", { count: this.cartItemsCount });
  }),

  cartTotal: computed("model.@each.{priceNet,availableCount}", function() {
    let total = 0;
    this.model.forEach(item => {
      total += item.get("priceNet") * item.get("availableCount");
    });
    return total;
  }),

  shopGroups: computed("model.@each.shop", function() {
    let shopGroups = [];
    this.model.forEach(cartItem => {
      const shopName = cartItem.get("shop.name");
      const shopId = cartItem.get("shop.id");
      let shop = shopGroups.findBy("id", shopId);
      if (isPresent(shop)) {
        shop.cartItems.pushObject(cartItem);
      } else {
        shop = { id: shopId, name: shopName, cartItems: [cartItem] };
        shopGroups.pushObject(shop);
      }
    });
    return shopGroups;
  }),

  checkoutDisabled: equal("cartItemsCount", 0),

  checkout(...args) {
    this.set("checkoutErrorMessage", null);
    this.send("checkout", ...args);
  },

  checkoutAnyway(...args) {
    this.set("checkoutErrorMessage", null);
    this.send("checkoutAnyway", ...args);
  },

  actions: {
    decrementCartItem(item) {
      this.cart.decrementCartItem(item);
    },

    removeCartItem(item) {
      this.cart.removeCartItem(item);
    }
  }
});
