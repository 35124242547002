import { computed } from "@ember/object";
import {
  alias,
  bool,
  match,
  readOnly,
  filter,
  gt,
  equal
} from "@ember/object/computed";
import { inject as service } from "@ember/service";
import DS from "ember-data";
import LoadableModel from "ember-data-storefront/mixins/loadable-model";

import config from "webclient/config/environment";
import moment from "moment";

export default DS.Model.extend(LoadableModel, {
  account: service(),

  createdAt: DS.attr("string"),
  updatedAt: DS.attr("string"),
  publishedAt: DS.attr("string"),

  name: DS.attr("string"),

  priceNetCents: DS.attr("number"),
  priceNet: alias("priceNetCents"),

  msrpCents: DS.attr("number"),
  msrpCurrency: DS.attr("string"),

  vat: DS.attr("number"),
  vatCode: DS.attr("string"),
  vatPercent: DS.attr("number"),

  iterationId: DS.attr("string"),
  descr: DS.attr("string"),
  visible: DS.attr("boolean"),
  inventory: DS.attr("number"),
  sizeDescr: DS.attr("string"),
  emptyAt: DS.attr("string"),
  sellingUnit: DS.attr("string"),
  packOf: DS.attr("number"),
  ingredients: DS.attr("string"),

  discountExcluded: DS.attr("boolean"),
  discountLabel: DS.attr("string"),
  discountTitle: DS.attr("string"),

  // One of sS, dD for Static (price set) or Dynamic (percent set)),
  discountSource: DS.attr("string"),
  discountedPercent: DS.attr("number"),
  discountedPrice: DS.attr("number"),

  campaign: DS.belongsTo("campaign", { async: true }),

  brand: DS.belongsTo("brand", { async: true }),
  shop: DS.belongsTo("shop", { async: true }),
  createdBy: DS.belongsTo("employee", { async: true }),

  // comments: DS.hasMany('productComment', { async: true }),
  imageMain: DS.belongsTo("image", { async: true }),
  images: DS.hasMany("image", { async: true }),
  productVariants: DS.hasMany("productVariant", { async: true }),
  cartItems: DS.hasMany("cartItem", { async: true }),
  category: DS.belongsTo("category", { async: true, inverse: "products" }),

  onSale: bool("discountedPrice"),
  discountStatic: match("discountSource", /s/i),

  elementId: computed("id", function() {
    return `product-${this.id}`;
  }),

  status: computed("inventory", function() {
    let i = this.inventory;
    if (typeof i === "undefined" || i === null) {
      return;
    }

    if (i === 0) {
      return "soldOut";
    } else if (i <= 2) {
      return "fewLeft";
    } else {
      return null;
    }
  }),

  isBulk: equal("sellingUnit", "case"),

  statusIsSoldOut: match("status", /soldOut/),
  statusIsFewLeft: match("status", /fewLeft/),

  isTooOldForFeed: computed("emptyAt", function() {
    let twoDaysAgo = config.twoDaysAgo;
    let emptyAt = this.emptyAt;
    return emptyAt && moment(emptyAt).diff(twoDaysAgo) < 0;
  }),

  shouldBeInFeed: computed(
    "visible",
    "shop.visible",
    "isTooOldForFeed",
    function() {
      // Order of checks is optimized for performance.
      return this.visible && this.get("shop.visible") && !this.isTooOldForFeed;
    }
  ),

  isAvailable: gt("inventory", 0),

  filteredImages: computed("images.@each.{sortOrder,visible}", function() {
    return this.images.filterBy("visible", true).sortBy("sortOrder");
  }),

  mainImage: alias("imageMain"),
  numImages: readOnly("filteredImages.length"),

  otherImages: filter("filteredImages", function(image) {
    let mainImage = this.mainImage;
    return image.get("id") !== mainImage.get("id");
  }),

  availableVariants: filter("productVariants.@each.inventory", function(
    variant
  ) {
    return variant.get("inventory") > 0;
  })

  // createComment(attrs) {
  //   let comment = this.store.createRecord('productComment', attrs);
  //   let comments = this.get('comments');
  //   comments.pushObject(comment);

  //   return comment.save();
  // }
});
