import { computed } from "@ember/object";
import Service from "@ember/service";
import Ember from "ember";
import config from "webclient/config/environment";

export default Service.extend({
  shouldTrack: computed(function() {
    return (
      !config.isLocalDevelopment && config.isInProduction && !Ember.testing
    );
  }),

  track(id) {
    if (typeof id !== "undefined" && this.shouldTrack) {
      window._fbq.push(["track", id, { value: "0.00", currency: "NOK" }]);
    }
  },

  finishedWelcome() {
    this.track("6015505749173");
  },

  addedToCart() {
    this.track("6015505763973");
  },

  completedPurchase() {
    this.track("6015505774373");
  }
});
