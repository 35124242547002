import DS from "ember-data";
import { computed } from "@ember/object";
import { gt } from "@ember/object/computed";

export default DS.Model.extend({
  status: DS.attr("string"),
  stripeId: DS.attr("string"),
  stripeSumCents: DS.attr("number"),
  stripeSumCurrency: DS.attr("string"),
  taxSumCents: DS.attr("number"),
  taxSumCurrency: DS.attr("string"),
  checkoutFeeSumCents: DS.attr("number"),
  checkoutFeeSumCurrency: DS.attr("string"),
  items: DS.hasMany("orderItem", { async: true }),
  user: DS.belongsTo("user"),
  invoiceAddress: DS.belongsTo("userAddress", { async: true }),
  deliveryAddress: DS.belongsTo("userAddress", { async: true }),

  productItems: computed("items.@each.shippingSku", function() {
    return this.items.filterBy("shippingSku.content", null);
  }),
  shippingItems: computed("items.@each.productVariant", function() {
    return this.items.filterBy("productVariant.content", null);
  }),

  hasCheckoutFee: gt("checkoutFeeSumCents", 0)
});
