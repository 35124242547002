import HeadDataService from "ember-cli-head/services/head-data";
import { inject as service } from "@ember/service";
import { computed } from "@ember/object";

export default HeadDataService.extend({
  router: service(),
  currentURL: computed("router.currentURL", function() {
    return `${
      window.location.protocol
    }//${window.location.host}${this.router.currentURL || ""}`;
  })
});
