import Route from "@ember/routing/route";
import { inject as service } from "@ember/service";
import classic from "ember-classic-decorator";

@classic
class SignupRoute extends Route {
  @service store;

  beforeModel() {
    this.controllerFor("application").set("noHeader", false);
    super.beforeModel(...arguments);
  }

  model() {
    return this.store.createRecord("user");
  }

  deactivate() {
    const model = this.get("controller.model");
    if (model.isNew) {
      model.destroyRecord();
    }
  }
}

export default SignupRoute;
