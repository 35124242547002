/*
|-----------------------------------------------------------------------------
| Z-index                                https://tailwindcss.com/docs/z-index
|-----------------------------------------------------------------------------
|
| Here is where you define your z-index utility values. By default we
| provide a sensible numeric scale. You can, of course, modify these
| values as needed.
|
| Class name: .z-{index}
|
*/

export default {
  auto: "auto",
  "0": 0,
  "10": 10,
  "20": 20,
  "30": 30,
  "40": 40,
  "50": 50
};
