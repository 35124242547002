import { inject as service } from "@ember/service";
import EmberRouter from "@ember/routing/router";
import { on } from "@ember/object/evented";
import config from "./config/environment";
import RouterScroll from "ember-router-scroll";

const Router = EmberRouter.extend(RouterScroll, {
  location: config.locationType,
  logger: service(),
  headData: service(),
  session: service(),
  setTitle(title) {
    this.headData.set("title", title);
  },

  didTransition: function() {
    this._super(...arguments);
    if (!this.get("session.isAuthenticated")) {
      let currentURL = this.currentURL;
      if (
        currentURL != "/login" &&
        currentURL != "/signup" &&
        currentURL != "/welcome" &&
        currentURL != "/"
      ) {
        this.session.set("beforeLoginUrl", currentURL);
      }
    }
  },

  logTransitions: on("didTransition", function() {
    const page = this.url;
    const title = this.getWithDefault("currentRouteName", "unknown");
    this.logger.trackPage(page, title);
    this.logger.pageview();
  }),
  rootURL: config.rootURL
});

Router.map(function() {
  this.route("products");
  this.route("product", { path: "/products/:product_id" });

  this.route("shops");
  this.route("shop", { path: "/shops/:shop_slug" }, function() {
    this.route("products", { path: "/" });
    this.route("brands");
    this.route("about");
  });

  this.route("brands");
  this.route("brand", { path: "/brands/:brand_id" });

  this.route("checkout");
  this.route("welcome");
  this.route("terms");
  this.route("cart");
  this.route("contact");
  this.route("about");
  this.route("support");
  this.route("orders");
  this.route("order", { path: "/orders/:order_id" });
  this.route("settings");
  this.route("login");
  this.route("logout");
  this.route("landingpage", { path: "/" });

  this.route("authorize", { path: "/authorize/:refresh_token" });
  this.route("authorize", { path: "/authorize/:refresh_token/*path" });

  this.route("not-found");
  this.route("four-oh-four", { path: "/*path" });
  this.route("receipt", { path: "receipt/:order_id" });
  this.route("signup");
  this.route("search");
  this.route("category", { path: "/categories/:category_id" });
});

export default Router;
