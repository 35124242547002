import Route from "@ember/routing/route";

export default Route.extend({
  afterModel(shop) {
    return this.store.loadRecords("product", {
      include: "images,brand",
      "filter[shopId]": shop.get("id")
    });
  }
});
