import { resolve } from "rsvp";
import { isEmpty } from "@ember/utils";
import Service, { inject as service } from "@ember/service";

export default Service.extend({
  session: service("session"),
  store: service(),

  load() {
    let userId = this.get("session.data.authenticated.user.data.id");
    if (!isEmpty(userId)) {
      return this.store.findRecord("user", userId).then(user => {
        this.set("user", user);
      });
    } else {
      return resolve();
    }
  }
});
