import Component from "@ember/component";
import { tns } from "tiny-slider/src/tiny-slider";
import { computed } from "@ember/object";

export default Component.extend({
  classNames: ["carousel"],
  images: null,
  slider: null,

  sortedImages: computed("images.@each.sortOrder", function() {
    return this.images.sortBy("sortOrder");
  }),

  didInsertElement() {
    const slider = tns({
      container: "#carousel-container",
      items: 1,
      autoplay: false,
      arrowKeys: true,
      mouseDrag: true,
      nav: false,
      nextButton: ".carousel-next",
      prevButton: ".carousel-prev"
    });
    this.set("slider", slider);
  },

  willDestroy() {
    if (this.slider === null) {
      return;
    }
    this.slider.destroy();
  }
});
