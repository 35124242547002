import { helper as buildHelper } from "@ember/component/helper";

/*
 * Tests if the two values are even
 */
export function isEven([value]) {
  return value % 2 === 0;
}

export default buildHelper(isEven);
