import { computed } from "@ember/object";
import DS from "ember-data";
import moment from "moment";

export default DS.Model.extend({
  firstName: DS.attr("string"),
  lastName: DS.attr("string"),
  username: DS.attr("string"),
  email: DS.attr("string"),
  avatarUrl: DS.attr("string"),
  cellphone: DS.attr("string"),
  createdAt: DS.attr("string"),
  password: DS.attr("string"),

  openOrder: DS.belongsTo("order", { async: true }),
  userSettings: DS.hasMany("userSetting", { async: true }),
  cartItems: DS.hasMany("cartItem", { async: true }),
  // productComments: DS.hasMany('productComment', { async: true }),
  userAddresses: DS.hasMany("userAddress", { async: true, inverse: "user" }),
  workRoles: DS.hasMany("employee", { async: true, inverse: "user" }),
  invoiceAddress: DS.belongsTo("userAddress", { async: true, inverse: null }),
  deliveryAddress: DS.belongsTo("userAddress", { async: true, inverse: null }),

  name: computed("firstName", "lastName", function() {
    return `${this.firstName} ${this.lastName}`;
  }),

  unixCreatedAtUTC: computed("createdAt", function() {
    let created = this.createdAt;
    return moment(created)
      .utc()
      .unix();
  })
});
