import { resolve } from "rsvp";
import Service, { inject as service } from "@ember/service";

export default Service.extend({
  account: service(),
  store: service(),
  inFlightPromise: null,

  // Convenience functions for setting and fetching user settings.
  // All functions return promises, which resolve into value returned by the server.
  setBool(key, boolValue) {
    return this._setValue(this, key, boolValue);
  },

  getBool(key) {
    return this._getValue(this, key).then(value => {
      if (typeof value === "undefined" || value === null) {
        return value;
      }
      return resolve(value === "true");
    });
  },

  setNumber(key, numberValue) {
    return this._setValue(this, key, numberValue);
  },

  getNumber(key) {
    return this._getValue(this, key).then(value => {
      return resolve(Number(value));
    });
  },

  setString(key, stringValue) {
    return this._setValue(this, key, stringValue);
  },

  getString(key) {
    return this._getValue(this, key);
  },

  setJSON(key, object) {
    return this._setValue(this, key, JSON.stringify(object));
  },

  getJSON(key) {
    return this._getValue(this, key).then(value => {
      return JSON.parse(value);
    });
  },

  _setValue(context, key, value) {
    return context._getSetting(context, key).then(setting => {
      if (setting.get("value") === `${value}`) {
        return setting.get("value");
      }
      setting.set("value", `${value}`);
      setting.set("user", context.get("account.user"));
      return setting.save().then(() => {
        return resolve(setting.get("value"));
      });
    });
  },

  _getValue(context, key) {
    return context._getSetting(context, key).then(setting => {
      return resolve(setting.get("value"));
    });
  },

  // Returns the object, not a value.
  _getSetting(context, key) {
    let store = context.get("store");
    let existingSettings = store.peekAll("userSetting");
    let foundSetting = null;
    existingSettings.forEach(setting => {
      if (setting.get("key") === key) {
        foundSetting = resolve(setting);
      }
    });
    if (foundSetting !== null) {
      return foundSetting;
    }

    // Don't ask more than once
    let promise = this.inFlightPromise;
    if (!promise) {
      let id = context.get("account.user.id");
      promise = store.query("userSetting", { "filter[userId]": id });
    }

    this.set("inFlightPromise", promise);

    // Fetch settings
    return promise.then(settings => {
      this.set("inFlightPromise", null);
      settings.forEach(setting => {
        if (setting.get("key") === key) {
          foundSetting = resolve(setting);
        }
      });
      if (foundSetting !== null) {
        return foundSetting;
      }
      let record = store.createRecord("user-setting", {
        user: context.get("acount.user"),
        key: key,
        value: null
      });
      return resolve(record);
    });
  }
});
