import Component from "@ember/component";
import { inject as service } from "@ember/service";
import { task } from "ember-concurrency-decorators";
import classic from "ember-classic-decorator";

@classic
class CategoryRow extends Component {
  @service store;
  tagName = "";

  products = [];

  init() {
    super.init(...arguments);
    this.loadProducts.perform();
  }

  @task
  *loadProducts() {
    const products = yield this.store.loadRecords("product", {
      page: {
        size: 5,
        number: 1
      },
      filter: {
        "category-id": `cattree(${this.category.id})`,
        published: true
      },
      include: ["brand"].join()
    });
    this.set("products", products);
  }
}

export default CategoryRow;
