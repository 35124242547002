import { all } from "rsvp";
import { inject as service } from "@ember/service";
import BasicRoute from "webclient/routes/basic";

export default BasicRoute.extend({
  titleToken(model) {
    return model.get("name");
  },

  logger: service(),
  account: service(),

  model(params, transition) {
    if (isNaN(params.product_id)) {
      transition.abort();
      return this.intermediateTransitionTo("/not-found");
    } else {
      return this.store.loadRecord("product", params.product_id, {
        include: [
          "product-variants",
          "product-variants.properties",
          "brand",
          "shop.image-main",
          "images",
          "category.ancestors"
        ].join()
      });
    }
  },

  afterModel(product) {
    let ogTags = {
      title: product.name,
      description: product.descr,
      image: product.mainImage.get("url")
    };
    this.headTags.registerOgTags(ogTags);
    let userId = this.get("account.user.id");
    let promises = [product.get("images"), product.get("productVariants")];
    if (typeof userId !== "undefined") {
      promises.push(
        this.store.findRecord("user", userId, {
          include: "cart-items",
          reload: true
        })
      );
    }
    return all(promises);
  },

  setupController(controller, model) {
    this._super(controller, model);
    controller.set("selectedValues", null);

    let variants = model.get("availableVariants");
    controller.set("allVariants", variants);
    let properties = [];
    variants.forEach(variant => {
      variant.get("properties").forEach(propertyPromise => {
        properties.pushObject(propertyPromise);
      });
    });
    return all(properties.uniq()).then(properties => {
      controller.set("properties", properties);
    });
  }
});
