import { helper as buildHelper } from "@ember/component/helper";

/*
 * Tests if the two values are equal
 */
export function isEqual([leftSide, rightSide]) {
  return leftSide === rightSide;
}

export default buildHelper(isEqual);
