import { validatePresence } from "ember-changeset-validations/validators/index";

import validateZipcode from "../validators/zipcode";

export default (store, translation) => {
  return {
    postalCode: [
      validatePresence({
        presence: true,
        message: translation.t(
          "checkout_address.address_form.postal_code.error.required"
        )
      }),
      validateZipcode(store, translation)
    ]
  };
};
