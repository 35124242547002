import { reads, gte, gt } from "@ember/object/computed";
import Component from "@ember/component";
import { computed, get } from "@ember/object";
import { inject as service } from "@ember/service";

export default Component.extend({
  intl: service(),
  item: null,
  localInventory: reads("item.productVariant.localInventory"),
  isAvailable: gte("localInventory", 0),
  isMany: gt("item.count", 1),
  someSoldOut: gt("item.soldOutCount", 0),
  decrement: () => {},
  remove: () => {},

  soldOutText: computed("intl.locale", "item.soldOutCount", function() {
    return this.intl.t("cart.cartItem.n_not_available", {
      count: get(this, "item.soldOutCount")
    });
  }),

  removeOneText: computed("intl.locale", "item.count", function() {
    if (get(this, "item.count") === 1) {
      return this.intl.t("cart.cartItem.remove");
    } else {
      return this.intl.t("cart.cartItem.removeOne");
    }
  }),

  removeAllText: computed("intl.locale", "item.count", function() {
    if (get(this, "item.count") === 2) {
      return this.intl.t("cart.cartItem.removeBoth");
    } else {
      return this.intl.t("cart.cartItem.removeAll");
    }
  }),

  actions: {
    decrement() {
      this.decrement(get(this, "item"));
    },

    remove() {
      this.remove(get(this, "item"));
    }
  }
});
