import Route from "@ember/routing/route";
import classic from "ember-classic-decorator";

@classic
class LoginRoute extends Route {
  beforeModel() {
    this.controllerFor("application").set("noHeader", false);
    super.beforeModel(...arguments);
  }
}

export default LoginRoute;
