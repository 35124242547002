import DS from "ember-data";

export default DS.JSONAPISerializer.extend({
  serializeAttribute(snapshot, json, key) {
    // Don't serialize empty values
    if (typeof snapshot.attr(key) !== "undefined") {
      this._super(...arguments);
    }
  }
});
