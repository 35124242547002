/*
|-----------------------------------------------------------------------------
| Minimum width                        https://tailwindcss.com/docs/min-width
|-----------------------------------------------------------------------------
|
| Here is where you define your minimum width utility sizes. These can
| be percentage based, pixels, rems, or any other units. We provide a
| couple common use-cases by default. You can, of course, modify
| these values as needed.
|
| Class name: .min-w-{size}
|
*/

export default {
  "0": "0",
  full: "100%"
};
