import DS from "ember-data";

export default DS.Model.extend({
  title: DS.attr("string"),
  stripeId: DS.attr("string"),
  weight: DS.attr("string"),
  volume: DS.attr("string"),
  length: DS.attr("string"),
  unitDescription: DS.attr("string"),
  shippingWeight: DS.attr("string"),
  shippingLength: DS.attr("string"),
  shippingHeight: DS.attr("string"),
  shippingWidth: DS.attr("string"),

  products: DS.belongsTo("product", { async: true }),
  category: DS.hasMany("category", { async: true })
});
