import Component from "@ember/component";
import { set, get } from "@ember/object";

export default Component.extend({
  classNameBindings: ["errors.length:error"],
  errors: null,
  label: null,
  type: "text",
  value: null,
  disabled: false,
  placeholder: null,
  oninput(e) {
    this.set("value", e.target.value);
  },

  init() {
    this._super(...arguments);
    if (!get(this, "errors")) {
      set(this, "errors", []);
    }
  }
});
