import { computed } from "@ember/object";
import { alias, mapBy, uniq, not, bool } from "@ember/object/computed";
import { inject as service } from "@ember/service";
import Controller from "@ember/controller";

export default Controller.extend({
  product: alias("model"),
  allVariants: null,
  properties: null,
  selectedValues: null,
  session: service(),
  cart: service(),

  url: computed("product.id", function() {
    // We need this because this is requested before the ID is in the URL, so window.location.href won't work if we enter from another route
    return (
      window.location.protocol +
      "//" +
      window.location.host +
      "/" +
      this.get("product.id")
    );
  }),

  /*
    Properties/variant picker
  */
  propertyKeys: mapBy("properties", "key"),
  uniquePropertyKeys: uniq("propertyKeys"),

  keyString: computed("uniquePropertyKeys.[]", function() {
    let propertyKeys = this.uniquePropertyKeys;
    if (
      typeof propertyKeys.get("firstObject") === "undefined" ||
      propertyKeys.get("length") === 0
    ) {
      return null;
    }
    let string = propertyKeys.get("firstObject").toLowerCase();
    propertyKeys.forEach(key => {
      if (key !== propertyKeys.get("firstObject")) {
        string += " og #{key}".toLowerCase();
      }
    });
    return string;
  }),

  // Boolean, returning whether a combination is available based on the current selection and inventory.
  selectionAvailable: computed(
    "selectedValues.[]",
    "selectedVariant.localInventory",
    function() {
      let selectedValues = this.selectedValues;
      if (!selectedValues || selectedValues.length === 0) {
        return true;
      }

      selectedValues.forEach(selectedValue => {
        if (selectedValue === null) {
          return true;
        }
      });

      let selectedVariant = this.selectedVariant;
      return selectedVariant && selectedVariant.get("localInventory") > 0;
    }
  ),

  noSelectionAvailable: not("selectionAvailable"),

  boughtAll: computed(
    "selectedVariant.{localInventory,inventory}",
    "noSelectionAvailable",
    function() {
      return (
        this.noSelectionAvailable &&
        this.get("selectedVariant.localInventory") !==
          this.get("selectedVariant.inventory")
      );
    }
  ),

  hintText: computed(
    "noVariantSelected",
    "keyString",
    "boughtAll",
    "noSelectionAvailable",
    function() {
      if (this.noVariantSelected && this.keyString) {
        return `Pick ${this.keyString} first.`;
      } else if (this.boughtAll) {
        return "You added all the available items in your cart. Thanks!";
      } else if (this.noSelectionAvailable) {
        return "This combination is unfortunately not available.";
      } else {
        return null;
      }
    }
  ),

  selectedVariant: computed("selectedValues.[]", "allVariants.[]", function() {
    let selectedValues = this.selectedValues;
    if (!selectedValues || selectedValues.length === 0) {
      if (this.get("allVariants.length") === 1) {
        return this.get("allVariants.firstObject");
      } else {
        return null;
      }
    }

    let variants = this.allVariants;
    let matchingVariants = [];
    variants.forEach(variant => {
      let variantProperties = variant.get("properties");
      let matching = true;
      selectedValues.forEach(selectedValue => {
        if (selectedValue === null) {
          return null;
        }
        let currentKey = selectedValue.get("key");
        let currentValue = selectedValue.get("value");
        variantProperties.forEach(variantProperty => {
          let key = variantProperty.get("key");
          let value = variantProperty.get("value");
          if (key === currentKey && value !== currentValue) {
            matching = false;
          }
        });
      });

      if (matching) {
        matchingVariants.push(variant);
      }
    });

    if (matchingVariants.length === 0) {
      return false;
    }

    return matchingVariants[0];
  }),

  hasSelectedVariant: bool("selectedVariant"),
  noVariantSelected: not("hasSelectedVariant"),

  isProductInCart: computed(
    "session.isAuthenticated",
    "product.id",
    "cart.cartItems.@each.product",
    function() {
      if (this.get("session.isAuthenticated")) {
        let cartItemProductIds = this.get("cart.cartItems").mapBy("product.id");
        return cartItemProductIds.includes(this.get("product.id"));
      } else {
        return false;
      }
    }
  ),

  actions: {
    valueChanged(values) {
      let hasNulls = false;
      if (values) {
        values.forEach(value => {
          if (value === null) {
            hasNulls = true;
          }
        });
      }
      if (!hasNulls) {
        this.set("selectedValues", values);
      } else {
        this.set("selectedValues", null);
      }
    }
  }
});
