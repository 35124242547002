import { inject as service } from "@ember/service";
import Route from "@ember/routing/route";

export default Route.extend({
  account: service(),
  session: service(),
  headTags: service(),

  beforeModel(transition) {
    this.controllerFor("application").set("noHeader", false);

    if (this.get("session.isAuthenticated")) {
      let account = this.account;
      const isEmployee = account.get("isEmployee");
      if (!isEmployee) {
        return account
          .hasFinishedWelcomeScreen()
          .then(result => {
            if (!result && this.routeName != "terms") {
              transition.abort();
              this.session.set("attemptedTransition", transition);
              return transition.send(false, "welcomeUser");
            }
          })
          .catch(() => {
            return this._super(transition);
          });
      }
    }
    return this._super(transition);
  }
});
