import Controller from "@ember/controller";
import { inject as service } from "@ember/service";

export default Controller.extend({
  session: service("session"),

  actions: {
    save(user) {
      let newUser = user;
      newUser
        .save()
        .catch(error => {
          if (error && error.errors[0] && error.errors[0].detail) {
            this.set("errorMessage", error.errors[0].detail.capitalize());
          }
          throw error;
        })
        .then(() => {
          this.session
            .authenticate(
              "authenticator:custom-oauth2",
              newUser.get("email"),
              newUser.get("password")
            )
            .catch(reason => {
              this.set("errorMessage", reason.error || reason);
            });
        });
    }
  }
});
