import Component from "@ember/component";
import { computed } from "@ember/object";

export default Component.extend({
  tagName: "",
  tabindex: -1,
  triggerComponent: "power-select-typeahead/trigger",
  beforeOptionsComponent: null,
  searchEnabled: false,
  loadingMessage: null,
  noMatchesMessage: null,
  onkeydown: () => {},

  // CPs
  concatenatedTriggerClasses: computed("triggerClass", function() {
    let classes = ["ember-power-select-typeahead-trigger"];
    let passedClass = this.triggerClass;
    if (passedClass) {
      classes.push(passedClass);
    }
    return classes.join(" ");
  }),

  concatenatedDropdownClasses: computed("dropdownClass", function() {
    let classes = ["ember-power-select-typeahead-dropdown"];
    let passedClass = this.dropdownClass;
    if (passedClass) {
      classes.push(passedClass);
    }
    return classes.join(" ");
  }),

  actions: {
    onKeyDown(select, e) {
      let action = this.onkeydown;

      // if user passes `onkeydown` action
      if (action && action(select, e) === false) {
        return false;
      } else {
        // if escape, then clear out selection
        if (e.keyCode === 27) {
          select.actions.choose(null);
        }
      }
    }
  }
});
