import Helper from "@ember/component/helper";
import { isPresent } from "@ember/utils";
import { inject as service } from "@ember/service";

export default Helper.extend({
  intl: service(),

  compute(property, options) {
    let showCurrency;

    if (isPresent(options)) {
      showCurrency = options.showCurrency || null;
    }

    if (property === null || isNaN(property)) {
      property = 0;
    }

    let sum = property / 100;

    if (showCurrency) {
      return `${sum} USD`;
    } else {
      return this.intl.formatNumber(sum, {
        style: "currency",
        currency: "USD"
      });
    }
  }
});
