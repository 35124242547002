import DS from "ember-data";

export default DS.Model.extend({
  name: DS.attr("string"),
  addressLine: DS.attr("string"),
  addressLine2: DS.attr("string"),
  postalCode: DS.attr("string"),
  postalPlace: DS.attr("string"),
  countryCode: DS.attr("string"),
  additionalInfo: DS.attr("string"),

  // ['street', 'postal', 'return']
  addrType: DS.attr("string"),

  shop: DS.belongsTo("shop")
});
