import DS from "ember-data";

export default DS.Model.extend({
  name: DS.attr("string"),

  // ['normal', 'pam_tech', 'pam_tech', 'pam_admin']
  employeeType: DS.attr("string"),

  user: DS.belongsTo("user"),
  shop: DS.belongsTo("shop")
});
