import Component from "@ember/component";

export default Component.extend({
  tagName: "span",
  changedPrice: false,
  showCurrency: false,
  price: null,
  oldPrice: null,
  newPrice: null
});
