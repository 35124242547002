import Component from "@ember/component";
import { task } from "ember-concurrency";
import { inject as service } from "@ember/service";
import { assert } from "@ember/debug";

export default Component.extend({
  store: service(),
  subcategories: null,
  categoryId: null,

  init(...args) {
    this._super(...args);
    assert(
      "A `categoryId` is required to render the `sub-categories` component.",
      typeof this.categoryId === "string"
    );
  },

  loadSubcategories: task(function*() {
    let subcategories = yield this.store.loadRecords("category", {
      filter: { "parent-id": this.categoryId, "with-subtree-products": true },
      include: "image",
      sort: "sortOrder"
    });
    this.set("subcategories", subcategories);
  }),

  didInsertElement() {
    this.loadSubcategories.perform();
  }
});
