import Controller from "@ember/controller";

export default Controller.extend({
  faqs: Object.freeze([
    {
      title: "Are there any questions yet?",
      slug: "any-questions",
      text: "No."
    }
  ])
});
