/*
|-----------------------------------------------------------------------------
| Height                                  https://tailwindcss.com/docs/height
|-----------------------------------------------------------------------------
|
| Here is where you define your height utility sizes. These can be
| percentage based, pixels, rems, or any other units. By default
| we provide a sensible rem based numeric scale plus some other
| common use-cases. You can, of course, modify these values as
| needed.
|
| Class name: .h-{size}
|
*/

export default {
  auto: "auto",
  px: "1px",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "6": "1.5rem",
  "8": "2rem",
  "10": "2.5rem",
  "12": "3rem",
  "16": "4rem",
  "24": "6rem",
  "32": "8rem",
  "48": "12rem",
  "64": "16rem",
  full: "100%",
  screen: "100vh"
};
