import { all } from "rsvp";
import { computed } from "@ember/object";
import Component from "@ember/component";

export default Component.extend({
  freezeAddresses: false,
  invoiceAddress: null,
  deliveryAddress: null,
  sameAddressesChanged: () => {},
  /**
   * checks the addresses to see if they are equal.
   * Returns true if they are, or the supplied value of set
   * @param  {String}  key   The property that was changed
   * @param  {Boolean} value The value it was changed to
   * @return {Boolean}       Whether the addresses should be or are equal
   */
  sameAddresses: computed("invoiceAddress.[]", "deliveryAddress.[]", {
    // eslint-disable-next-line ember/require-return-from-computed
    get() {
      let invoice = this.invoiceAddress;
      let delivery = this.deliveryAddress;
      all([invoice, delivery]).then(addresses => {
        invoice = addresses[0];
        delivery = addresses[1];
        let sameAddresses;
        if (!invoice) {
          sameAddresses = false;
        } else {
          sameAddresses = invoice.isEqualTo(delivery);
        }

        // We shouldn't be solving this this way, but here we are
        // eslint-disable-next-line ember/no-side-effects
        this.set("sameAddresses", sameAddresses);
        this.sameAddressesChanged(sameAddresses);
      });
    },
    set(key, value) {
      this.sameAddressesChanged(value);
      return value;
    }
  })
});
