import { alias, gt, readOnly } from "@ember/object/computed";
import Component from "@ember/component";
import { computed } from "@ember/object";
import { inject as service } from "@ember/service";

export default Component.extend({
  tagName: "",
  cart: service(),
  account: service(),
  session: service(),
  notifications: service(),
  intl: service(),
  store: service(),
  cartCount: alias("cart.cartItemsCount"),
  cartActive: gt("cartCount", 0),
  firstName: alias("user.firstName"),
  user: alias("account.user"),
  isAccountOpen: false,
  menuIsOpen: false,
  notificationIsOpen: readOnly("notifications.notificationIsOpen"),
  notificationType: readOnly("notifications.notificationType"),
  notificationTypeClass: computed("notificationType", function() {
    let type = this.notificationType;
    if (typeof type !== "undefined" && type !== null) {
      return `header-notification--${type}`;
    } else {
      return "";
    }
  }),

  notificationText: computed("intl.locale", "notificationType", function() {
    let type = this.notificationType;
    if (typeof type !== "undefined" && type !== null) {
      return this.intl.t(`notifications.${type}`);
    } else {
      return "";
    }
  }),

  cartNotificationOpen: computed(
    "notificationIsOpen",
    "notificationType",
    function() {
      return this.notificationIsOpen && this.notificationType === "cart";
    }
  ),

  actions: {
    toggleAccountMenu() {
      // eslint-disable-next-line ember/closure-actions
      this.sendAction("toggleAccountMenu", ...arguments);
    },

    toggleMenu() {
      if (this.menuIsOpen) {
        // eslint-disable-next-line ember/closure-actions
        this.sendAction("closeMainMenu");
      } else {
        // eslint-disable-next-line ember/closure-actions
        this.sendAction("openMainMenu");
      }
    },

    toggleNotification() {
      this.toggleProperty("notificationIsOpen");
      return false;
    }
  }
});
