import Controller from "@ember/controller";
import { computed } from "@ember/object";

export default Controller.extend({
  // ESLINT rule disabled to introduce new rule. Should be fixed.
  // eslint-disable-next-line ember/no-deeply-nested-dependent-keys-with-each
  groups: computed(
    "model.items.@each.{shop.id,productItem,shippingItem}",
    function() {
      let items = this.get("model.items");
      let shops = items.mapBy("shop").uniqBy("id");
      return shops.map(function(shop) {
        let shopItems = items.filter(function(item) {
          return item.belongsTo("shop").id() == shop.get("id");
        });
        return {
          shop: shop,
          productItems: shopItems.filterBy("productItem"),
          shippingItems: shopItems.filterBy("shippingItem")
        };
      });
    }
  )
});
