import { inject as service } from "@ember/service";
import AuthenticatedRouteMixin from "ember-simple-auth/mixins/authenticated-route-mixin";
import BasicRoute from "webclient/routes/basic";

export default BasicRoute.extend(AuthenticatedRouteMixin, {
  account: service(),
  model() {
    return this.get("account.user");
  },
  setupController(controller, model) {
    this._super(controller, model);
    controller.fetchSettings();
  }
});
