import * as viewportUnitsBuggyfill from "viewport-units-buggyfill";
import { inject as service } from "@ember/service";

export default {
  name: "viewport-units-buggyfill",
  fastboot: service(),
  initialize() {
    if (!this.fastboot.isFastBoot) {
      viewportUnitsBuggyfill.init();
    }
  }
};
