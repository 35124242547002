import RootRoute from "webclient/routes/root";
import { hash } from "rsvp";

export default RootRoute.extend({
  model(params) {
    return hash({
      categories: this.store.loadRecords("category", {
        filter: {
          parent_id: params.category_id,
          "with-subtree-products": true
        },
        sort: "sort-order"
      }),
      parentCategory: this.store.loadRecord("category", params.category_id, {
        include: "ancestors"
      })
    });
  }
});
