import { hash } from "rsvp";
import RootRoute from "webclient/routes/root";

export default RootRoute.extend({
  titleToken: "Products",

  model() {
    return hash({
      products: this.store.loadRecords("product", {
        include: "shop,image-main,brand",
        "page[number]": 1,
        "page[size]": 8,
        filter: { promoted: true }
      }),
      categories: this.store.loadRecords("category", {
        include: "products.brand,products.shop,image",
        filter: { "parent-id": "null", "with-subtree-products": true }
      }),
      shops: this.store.loadRecords("shop", {
        include: "image-main",
        filter: { visible: true, showInList: true }
      })
    });
  }
});
