import Component from "@ember/component";
import { run } from "@ember/runloop";
import { create as createLadda } from "ladda";

export default Component.extend({
  classNames: ["ladda-button"],
  attributeBindings: ["data-style", "type", "disabled"],
  "data-style": "zoom-out",
  tagName: "button",
  type: "button",
  loading: false,
  disabled: false,
  ladda: null,
  action: () => {},

  didReceiveAttrs() {
    this._super(...arguments);
    if (this.ladda === null) {
      return;
    }
    if (this.loading) {
      this.ladda.start();
    } else {
      this.ladda.stop();
    }
  },

  didInsertElement() {
    run.scheduleOnce("afterRender", this, this.setupLadda);
  },

  setupLadda() {
    this.set("ladda", createLadda(this.element));
  },

  willDestroyElement() {
    this.ladda.remove();
  },

  click() {
    this.action();
  }
});
