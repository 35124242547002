/*
|-----------------------------------------------------------------------------
| Fonts                                    https://tailwindcss.com/docs/fonts
|-----------------------------------------------------------------------------
|
| Here is where you define your project's font stack, or font families.
| Keep in mind that Tailwind doesn't actually load any fonts for you.
| If you're using custom fonts you'll need to import them prior to
| defining them here.
|
| By default we provide a native font stack that works remarkably well on
| any device or OS you're using, since it just uses the default fonts
| provided by the platform.
|
| Class name: .font-{name}
|
*/

export default {
  sans: [
    "LatoLatin",
    "Lato",
    "system-ui",
    "BlinkMacSystemFont",
    "-apple-system",
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    "sans-serif"
  ],
  serif: ["Georgia", "Cambria", "Times New Roman", "Times", "serif"],
  mono: [
    "Menlo",
    "Monaco",
    "Consolas",
    "Liberation Mono",
    "Courier New",
    "monospace"
  ]
};
