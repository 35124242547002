import { inject as service } from "@ember/service";
import Controller from "@ember/controller";
import SettingsValidations from "../validations/settings";

export default Controller.extend({
  SettingsValidations: SettingsValidations,
  account: service(),

  savingBasic: false,
  savingNotificationSettings: false,
  sameAddresses: true,

  generalInformationNotification: true,

  fetchSettings() {
    this.get("account.settings")
      .getBool("generalInformationNotification")
      .then(value => {
        this.set("generalInformationNotification", value);
      });
  },

  actions: {
    saveBasic(changeset) {
      this.set("savingBasic", true);
      return changeset
        .validate()
        .then(() => {
          if (!changeset.get("isValid")) {
            return;
          }

          return changeset.save().catch(() => {
            this.get("model.errors").forEach(({ attribute, message }) => {
              changeset.pushErrors(attribute, message);
            });
          });
        })
        .finally(() => {
          this.set("savingBasic", false);
        });
    },

    saveNotificationSettings() {
      this.set("savingNotificationSettings", true);
      return this.get("account.settings")
        .setBool(
          "generalInformationNotification",
          this.generalInformationNotification
        )
        .finally(() => {
          this.set("savingNotificationSettings", false);
        });
    }
  }
});
