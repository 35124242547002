import { inject as service } from "@ember/service";
import Route from "@ember/routing/route";
import AuthenticatedRouteMixin from "ember-simple-auth/mixins/authenticated-route-mixin";

export default Route.extend(AuthenticatedRouteMixin, {
  stripe: service("stripev3"),
  currentUser: service(),

  beforeModel() {
    this._super(...arguments);
    return this.stripe.load();
  },

  model() {
    return this.currentUser.load().then(() => {
      return this.store
        .createRecord("order", {
          status: "makeme",
          user: this.get("currentUser.user")
        })
        .save()
        .then(order => {
          return this.store.findRecord("order", order.id, {
            reload: true,
            include:
              "items.shop,items.product-variant.product.images,items.shipping-sku.shipping-product"
          });
        });
    });
  }
});
