import { Promise, resolve } from "rsvp";
import { computed } from "@ember/object";
import { oneWay } from "@ember/object/computed";
import Service, { inject as service } from "@ember/service";
import Ember from "ember";
import DS from "ember-data";

export default Service.extend({
  settings: service("user-settings"),
  cart: service(),
  logger: service(),
  store: service(),
  session: service(),
  currentUser: service(),
  user: oneWay("currentUser.user"),

  configureLogger() {
    let user = this.get("currentUser.user");
    if (Ember.testing) {
      return;
    }
    if (typeof user === "undefined" || user === null) {
      return;
    }
    let logger = this.logger;
    logger.setUser(user);

    // Set event listeners
    user.on("didUpdate", this, this.didUpdateUser);
  },

  didUpdateUser() {
    return this.logger.updateUser(this.get("currentUser.user"));
  },

  /**
   * Grabs the existing invoice address or creates a new one.
   * @return {Ember.RSVP.Promise} A Promise that resolves to a userAddress
   */
  // Disabling computed property dependencies for this because
  // it doesn't make sense to depend on the store here and this is a pretty bad
  // use of computed properties so I'm not sure how useful it is to try to fix it.
  // eslint-disable-next-line ember/require-computed-property-dependencies
  invoiceAddress: computed("currentUser.user.invoiceAddress", function() {
    let result = this.get("currentUser.user.invoiceAddress").then(
      invoiceAddress => {
        return new Promise(resolve => {
          if (invoiceAddress === null) {
            resolve(
              this.store.createRecord("userAddress", {
                user: this.get("currentUser.user")
              })
            );
          } else {
            resolve(invoiceAddress);
          }
        });
      }
    );
    return DS.PromiseObject.create({
      promise: result
    });
  }),

  /**
   * Grabs the existing delivery address or creates a new one.
   * @return {Ember.RSVP.Promise} A Promise that resolves to a userAddress
   */
  // Disabling computed property dependencies for this because
  // it doesn't make sense to depend on the store here and this is a pretty bad
  // use of computed properties so I'm not sure how useful it is to try to fix it.
  // eslint-disable-next-line ember/require-computed-property-dependencies
  deliveryAddress: computed("currentUser.user.deliveryAddress", function() {
    let result = this.get("currentUser.user.deliveryAddress").then(
      deliveryAddress => {
        return new Promise(resolve => {
          if (deliveryAddress === null) {
            resolve(
              this.store.createRecord("userAddress", {
                user: this.get("currentUser.user")
              })
            );
          } else {
            resolve(deliveryAddress);
          }
        });
      }
    );
    return DS.PromiseObject.create({
      promise: result
    });
  }),

  // This rule doesn't work properly here because we're going through a proxy.
  // Like the TODO-comment says, we should use snapshots to count the workroles
  // or just delete this.
  // eslint-disable-next-line ember/require-computed-property-dependencies
  isEmployee: computed("currentUser.user.workRoles.length", function() {
    // TODO: Use snapshots instead
    return this.get("currentUser.user.data.workRoles.length") > 0;
  }),

  isPamEmployee: computed(
    "isEmployee",
    "currentUser.user.workRoles.[]",
    function() {
      if (!this.isEmployee) {
        return resolve(false);
      }
      return this.get("currentUser.user.workRoles").then(roles => {
        roles = roles.filter(role => {
          let employeeType = role.get("employeeType");
          if (typeof employeeType !== "undefined" && employeeType !== null) {
            return employeeType.indexOf("pam_") !== -1;
          }
        });
        return roles.get("length") > 0;
      });
    }
  ),

  hasFinishedWelcomeScreen() {
    return this.settings.getBool("welcomeScreenDone").then(hasFinished => {
      return hasFinished;
    });
  },

  // @private
  _createCartItem(variant) {
    return variant
      .get("product")
      .then(product => {
        if (product.get("iterationId")) {
          return resolve(product);
        } else {
          return product.reload();
        }
      })
      .then(product => {
        let attrs = {
          priceNet: product.get("priceNet"),
          count: 1,
          productIterationId: product.get("iterationId"),
          productVariant: variant,
          user: this.get("currentUser.user")
        };
        let cartItem = this.store.createRecord("cartItem", attrs);
        return cartItem.save();
      });
  },

  // @private
  findCartItemByProductVariant(productVariant) {
    return this.get("currentUser.user.cartItems")
      .filter(item => item.get("productVariant") === productVariant)
      .get("firstObject");
  }
});
