import Service from "@ember/service";
import { run } from "@ember/runloop";

export default Service.extend({
  notificationType: null,
  notificationIsOpen: false,
  timeoutId: null,
  showNotification(type = "cart", duration = 2000) {
    this.set("notificationType", type);
    if (!this.notificationIsOpen) {
      this.set("notificationIsOpen", true);
    }
    let timeoutId = this.timeoutId;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    this.set(
      "timeoutId",
      setTimeout(() => {
        run(this, function() {
          if (this.isDestroyed) {
            return;
          }
          this.set("notificationIsOpen", false);
          this.set("timeoutId", null);
        });
      }, duration)
    );
  }
});
