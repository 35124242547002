import { helper as buildHelper } from "@ember/component/helper";
import { assert } from "@ember/debug";
import { isPresent } from "@ember/utils";

export function perCent(value) {
  assert(
    "You have to pass in a value to the per-cent-helper",
    isPresent(value)
  );
  return `${value / 100}%`;
}

export default buildHelper(perCent);
