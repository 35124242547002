import { inject as service } from "@ember/service";
import Controller from "@ember/controller";
import checkoutValidations from "../validations/checkout";
import zipcodeValidations from "../validations/checkoutZipcode";
import Changeset from "ember-changeset";
import lookupValidator from "ember-changeset-validations";

export default Controller.extend({
  intl: service(),
  account: service(),
  store: service(),
  isTaxAmount: false,
  changeset: null,
  zipcodeChangeset: null,

  init() {
    this._super(...arguments);
    this.send("setUpChangeset");
  },

  actions: {
    updateTaxAmount(isTaxAmount) {
      this.set("isTaxAmount", isTaxAmount);
    },
    setUpChangeset() {
      let address = this.store.peekAll("user-address").findBy("isNew", true);
      address =
        address ||
        this.store.createRecord("user-address", {
          firstName: this.get("account.user.firstName"),
          lastName: this.get("account.user.lastName"),
          user: this.get("account.user")
        });

      const CheckoutValidations = checkoutValidations(this.store, this.intl);
      const changeset = new Changeset(
        address,
        lookupValidator(CheckoutValidations),
        CheckoutValidations
      );
      this.set("changeset", changeset);

      const ZipcodeValidations = zipcodeValidations(this.store, this.intl);
      const zipcodeChangeset = new Changeset(
        { postalCode: address.postalcode },
        lookupValidator(ZipcodeValidations),
        ZipcodeValidations,
        { skipValidate: true }
      );
      this.set("zipcodeChangeset", zipcodeChangeset);
    }
  }
});
