import { computed } from "@ember/object";
import { inject as service } from "@ember/service";
import Component from "@ember/component";

export default Component.extend({
  session: service(),
  cart: service(),
  tagName: "",
  product: null,
  isProductInCart: computed(
    "session.isAuthenticated",
    "product.id",
    "cart.cartItems.@each.product",
    function() {
      if (this.get("session.isAuthenticated")) {
        let cartItemProductIds = this.get("cart.cartItems").mapBy("product.id");
        return cartItemProductIds.includes(this.get("product.id"));
      } else {
        return false;
      }
    }
  )
});
