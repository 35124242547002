import { not, gt } from "@ember/object/computed";
import { computed } from "@ember/object";
import DS from "ember-data";

export default DS.Model.extend({
  inventory: DS.attr("number"),
  product: DS.belongsTo("product", { async: true }),
  cartItem: DS.belongsTo("cartItem", { async: true }),
  properties: DS.hasMany("productVariantProperty", {
    async: true,
    inverse: "productVariants"
  }),

  /**
   * localInventory is a number for how many items of this variant is available
   * for the logged in user. It takes into account in-progress orders and what
   * the user has in her cart.
   * The returned number can be positive or negative. An example could be if
   * `localInventory` is `-1`, the user has one item more item in her cart than
   * what is available. If it is `2`, the user can add two more items to her cart.
   * @return {Integer} A number representing the available inventory for the variant, relative to the user.
   */
  localInventory: computed(
    "inventory",
    "cartItem.{count,orderItem.countCustomer}",
    function() {
      let inventory = this.inventory;
      if (typeof inventory !== "undefined" && inventory !== null) {
        let cartItem = this.cartItem;
        let orderItem;

        if (cartItem !== null) {
          orderItem = cartItem.get("orderItem");
        }

        if (orderItem) {
          let orderItemCount = orderItem.get("countCustomer");
          if (orderItemCount) {
            inventory += orderItemCount;
          }
        }
        if (cartItem) {
          let cartItemCount = cartItem.get("count");
          if (cartItemCount) {
            inventory -= cartItemCount;
          }
        }
      }
      return inventory;
    }
  ),

  available: gt("localInventory", 0),
  unavailable: not("available"),

  propertyCSV: computed("properties.[]", function() {
    let string = "";
    let properties = this.properties;
    properties.forEach(property => {
      if (string.length > 0) {
        string += ", ";
      }
      string += property.get("value");
    });
    return string;
  })
});
