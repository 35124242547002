import Component from "@ember/component";
import { inject as service } from "@ember/service";
import classic from "ember-classic-decorator";

@classic
class CategoryProducts extends Component {
  @service infinity;

  tagName = "";

  products = [];

  didReceiveAttrs() {
    this.loadProducts();
  }

  loadProducts() {
    const products = this.infinity.model("product", {
      perPage: 60,
      startingPage: 1,
      perPageParam: "page[size]",
      pageParam: "page[number]",
      totalPagesParam: "meta.page-count",
      storeFindMethod: "loadRecords",
      filter: {
        "category-id": `cattree(${this.categoryId})`,
        published: true
      },
      include: ["brand"].join()
    });
    this.set("products", products);
  }
}

export default CategoryProducts;
