import { all } from "rsvp";
import BasicRoute from "webclient/routes/basic";

export default BasicRoute.extend({
  model(params) {
    return all([
      this.store.loadRecords("shopBrand", {
        "filter[brandId]": params.brand_id
      }),
      this.store.loadRecords("product", {
        "filter[brandId]": params.brand_id,
        include: "brand"
      })
    ]).then(() => {
      return this.store.findRecord("brand", params.brand_id, { reload: true });
    });
  }
});
