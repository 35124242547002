/**
  Calculates the intersection of two arrays. This method returns a new array
  filled with the records that the two passed arrays share with each other.
  If there is no intersection, an empty array will be returned.
  ```js
  import { intersection } from '../utils/array-helpers';

  let array1 = [1, 2, 3, 4, 5];
  let array2 = [1, 3, 5, 6, 7];
  intersection(array1, array2); // [1, 3, 5]

  let array1 = [1, 2, 3];
  let array2 = [4, 5, 6];
  intersection(array1, array2); // []
  ```
  @public
  @method intersection
  @param {Array} array1 The first array
  @param {Array} array2 The second array
  @return {Array} The intersection of the two passed arrays.
*/
export function intersection(array1, array2) {
  let result = [];
  array1.forEach(element => {
    if (array2.indexOf(element) >= 0) {
      result.push(element);
    }
  });

  return result;
}
