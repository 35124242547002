/*
|-----------------------------------------------------------------------------
| Maximum width                        https://tailwindcss.com/docs/max-width
|-----------------------------------------------------------------------------
|
| Here is where you define your maximum width utility sizes. These can
| be percentage based, pixels, rems, or any other units. By default
| we provide a sensible rem based scale and a "full width" size,
| which is basically a reset utility. You can, of course,
| modify these values as needed.
|
| Class name: .max-w-{size}
|
*/

export default {
  xs: "20rem",
  sm: "30rem",
  md: "40rem",
  lg: "50rem",
  xl: "60rem",
  "2xl": "70rem",
  "3xl": "80rem",
  "4xl": "90rem",
  "5xl": "100rem",
  full: "100%"
};
