import { helper as buildHelper } from "@ember/component/helper";

/*
 * Tests if the value is falsy
 */
export function isNot([value]) {
  return !value;
}

export default buildHelper(isNot);
