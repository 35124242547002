import { computed } from "@ember/object";
import { inject as service } from "@ember/service";
import Component from "@ember/component";

export default Component.extend({
  cart: service(),

  productCount: computed(
    "isProductInCart",
    "product.id",
    "cart.cartItems.@each.count",
    function() {
      if (!this.isProductInCart) {
        return 0;
      }

      let cartItem = this.get("cart.cartItems").findBy(
        "productVariantId",
        this.get("product.id")
      );
      return cartItem.count;
    }
  ),

  actions: {
    addToCart(product) {
      this.cart.addToCart.perform(product.get("availableVariants.firstObject"));
    },
    incrementQuantity(product, variant) {
      if (variant) {
        this.cart.addToCart.perform(variant);
      } else {
        this.cart.addToCart.perform(
          product.get("availableVariants.firstObject")
        );
      }
    },
    decrementQuantity(product) {
      let cartItem = this.get("cart.cartItems").findBy(
        "productVariantId",
        product.id
      );
      this.cart.decrementCartItem(cartItem);
    }
  }
});
