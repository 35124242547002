import DS from "ember-data";
import config from "webclient/config/environment";
import { computed } from "@ember/object";

export default DS.Model.extend({
  url: DS.attr("string"),
  thumbnailUrl: DS.attr("string"),
  width: DS.attr("number"),
  height: DS.attr("number"),
  visible: DS.attr("boolean"),
  sortOrder: DS.attr("number"),

  // Number in percentage
  cOffset: DS.attr("number"),

  // "v" or "h"
  cAxis: DS.attr("string"),

  product: DS.belongsTo("product", { inverse: "images", async: true }),
  shop: DS.belongsTo("shop", { inverse: "images" }),

  path: computed("url", function() {
    let url = new URL(this.url);
    return url.pathname;
  }),

  isImgixImage: computed("url", function() {
    return this.url.indexOf(config.APP.imgix.source) !== -1;
  })
});
