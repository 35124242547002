import RootRoute from "webclient/routes/root";

export default RootRoute.extend({
  setupController(controller) {
    if (controller.q !== null) {
      controller.set("initialQuery", controller.q);
      controller.search(controller.initialQuery);
    }
  }
});
