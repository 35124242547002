define("webclient/components/imgix-bg", ["exports", "ember-cli-imgix/components/imgix-bg"], function (_exports, _imgixBg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _imgixBg.default;
    }
  });
});
