/*
|-----------------------------------------------------------------------------
| Tracking (letter spacing)       https://tailwindcss.com/docs/letter-spacing
|-----------------------------------------------------------------------------
|
| Here is where you define your letter spacing values, or as we call
| them in Tailwind, tracking.
|
| Class name: .tracking-{size}
|
*/

export default {
  tight: "-0.05em",
  normal: "0",
  wide: "0.05em"
};
