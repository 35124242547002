import { inject as service } from "@ember/service";
import Route from "@ember/routing/route";
import { set, get, computed } from "@ember/object";
import config from "../config/environment";
import RSVP from "rsvp";
import Ember from "ember";
import ApplicationRouteMixin from "ember-simple-auth/mixins/application-route-mixin";

export default Route.extend(ApplicationRouteMixin, {
  routeAfterAuthentication: computed("session.beforeLoginUrl", function() {
    const beforeLoginUrl = this.session.beforeLoginUrl;

    if (beforeLoginUrl) {
      return beforeLoginUrl;
    }

    return config.routeAfterAuthentication;
  }),

  currentUser: service(),
  notifications: service(),

  title(tokens) {
    if (tokens.length > 0) {
      return `${tokens.join(" - ")} - Organic Shopping`;
    } else {
      return "Organic Shopping";
    }
  },

  logger: service(),
  cart: service(),
  account: service(),
  intl: service(),
  headTags: service(),
  session: service(),
  errorMessage: null,

  beforeModel() {
    this.logger.initializeMetrics();
    return this._loadCurrentUser();
  },

  afterModel() {
    return RSVP.all([
      this._super(...arguments),
      this.store.findAll("shop", { "filter[visible]": true, reload: true })
    ]);
  },

  sessionAuthenticated() {
    this._super(...arguments);
    this.session.set("beforeLoginUrl", null);

    return this._loadCurrentUser()
      .then(() => this.account.configureLogger())
      .then(() => this.cart.addPendingItemToCart.perform());
  },

  _loadCurrentUser() {
    this.intl.setLocale(["en-us"]); // TODO: This should be dynamic
    return this.currentUser
      .load()
      .catch(() => this.session.invalidate())
      .then(() => this.cart.reload());
  },

  actions: {
    completedWelcomeScreen() {
      this.sessionAuthenticated();
    },

    willTransition() {
      this.headTags.resetOgTags();
      this.send("closeGlobalMenus");
    },

    closeGlobalMenus() {
      this.send("closeMainMenu");
      this.controllerFor("application").set("isAccountOpen", false);
    },

    closeMenus() {
      this.controllerFor("application").setProperties({
        menuIsOpen: false,
        isAccountOpen: false
      });
    },

    openMainMenu() {
      this.send("closeMenus");
      let controller = this.controllerFor("application");
      set(controller, "menuIsOpen", true);
      controller.menuStateChanged();
    },

    closeMainMenu() {
      let controller = this.controllerFor("application");
      set(controller, "menuIsOpen", false);
      controller.menuStateChanged();
    },

    toggleAccountMenu() {
      this.send("closeMainMenu");
      let controller = this.controllerFor("application");
      controller.toggleProperty("isAccountOpen");
    },

    sessionAuthenticationFailed(error) {
      set(this, "errorMessage", error);
    },

    sessionInvalidationSucceeded() {
      this._super();
    },

    authorizationFailed() {
      // Called if a request returns a 401
      // TODO: Do something smarter here, like re-authorize with refresh token
      // and/or facebook token before giving up.
      if (get(this, "session.isAuthenticated")) {
        get(this, "session").invalidate();
      }
    },

    welcomeUser() {
      this.transitionTo("welcome");
    },

    error(error) {
      if (error.status === 404) {
        return this.transitionTo("/not-found", error);
      } else {
        if (typeof Ember.onError === "function") {
          Ember.onerror(error);
        } else {
          throw error;
        }
        return true;
      }
    }
  }
});
