import { inject as service } from "@ember/service";
import Route from "@ember/routing/route";
import AuthenticatedRouteMixin from "ember-simple-auth/mixins/authenticated-route-mixin";

export default Route.extend(AuthenticatedRouteMixin, {
  currentUser: service(),
  model() {
    return this.currentUser.load().then(() => {
      return this.get("currentUser.user");
    });
  }
});
