import { computed } from "@ember/object";
import { mapBy, filter, alias, readOnly } from "@ember/object/computed";
import { inject as service } from "@ember/service";
import DS from "ember-data";

export default DS.Model.extend({
  account: service(),
  currentUser: service(),
  name: DS.attr("string"),
  descr: DS.attr("string"),
  visible: DS.attr("boolean"),
  displayAddr: DS.attr("string"),
  slug: DS.attr("string"),
  showInList: DS.attr("boolean"),

  products: DS.hasMany("product", { async: true }),
  imageMain: DS.belongsTo("image", { async: true }),
  images: DS.hasMany("image", { async: true }),
  shopBrands: DS.hasMany("shopBrand", { async: true }),
  addresses: DS.hasMany("shopAddress", { async: true }),
  shippingSku: DS.belongsTo("shippingSku", { async: true }),

  brands: mapBy("shopBrands", "brand"),
  verifiedBrands: filter("brands", function(brand) {
    return !!brand;
  }),

  streetAddress: computed("addresses.@each.addrType", function() {
    return this.addresses.filterBy("addrType", "street").get("firstObject");
  }),

  filteredImages: computed("images.@each.{sortOrder,visible}", function() {
    return this.images.filterBy("visible", true).sortBy("sortOrder");
  }),

  mainImage: alias("imageMain"),
  numImages: readOnly("filteredImages.length"),
  otherImages: filter("filteredImages", function(image) {
    let mainImage = this.mainImage;
    return image.get("id") !== mainImage.get("id");
  })
});
