import RootRoute from "webclient/routes/root";

export default RootRoute.extend({
  titleToken: "Brands",
  prevLoad: null,

  model() {
    let now = Date.now();
    let prev = this.prevLoad;
    // 1 hour: seconds * minutes * milliseconds
    if (prev + 60 * 60 * 1000 < now) {
      this.set("prevLoad", now);
      return this.store.query("brand", {
        include: "shop-brands,shop-brands.shop"
      });
    }
    return this.store.peekAll("brand");
  },

  setupController(controller, model) {
    this._super(controller, model);
    controller.set("brandsLoaded", true);
  }
});
