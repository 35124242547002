import RootRoute from "webclient/routes/root";

export default RootRoute.extend({
  titleToken: "Farms",
  prevLoad: null,

  model() {
    return this.store.peekAll("shop").filterBy("showInList", true);
  },

  setupController(controller, model) {
    controller.set("model", this.theFilter(model));
  },

  theFilter(shops) {
    return shops.filter(shop => {
      let visible = shop.get("visible");
      let hidden = shop.get("hidden");
      if (typeof visible !== "undefined" && typeof hidden !== "undefined") {
        return visible && !hidden;
      } else {
        if (typeof visible !== "undefined") {
          return visible;
        }
        if (typeof hidden !== "undefined") {
          return !hidden;
        }
        return false;
      }
    });
  }
});
