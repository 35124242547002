import DS from "ember-data";

export default DS.Model.extend({
  firstName: DS.attr("string"),
  lastName: DS.attr("string"),
  addressLine: DS.attr("string"),
  addressLine2: DS.attr("string"),
  postalCode: DS.attr("string"),
  countryCode: DS.attr("string"),
  countryName: DS.attr("string"),
  additionalInfo: DS.attr("string"),
  state: DS.attr("string"),
  city: DS.attr("string"),
  user: DS.belongsTo("user"),
  country: DS.belongsTo("country"),

  nonAddressProperties: Object.freeze(["countryCode"]),

  /**
   * Checks to see if the actual address contains any data
   * @return {Boolean} Whether or not there's an address set
   */
  hasValue() {
    let hasValue = false;
    this.eachAttribute(name => {
      if (hasValue === true || this.nonAddressProperties.includes(name)) {
        return;
      }
      if (this.get(`${name}.length`) > 0) {
        hasValue = true;
      }
    });
    return hasValue;
  },

  /**
   * Replaces this address with the one passed in.
   * @param  {UserAddress} address The address to replace this one with
   * @return {UserAddress}         The converted address
   */
  replaceWith(address) {
    this.eachAttribute(name => {
      this.set(name, address.get(name));
    });
    return this;
  },

  /**
   * Compares this address to the passed in one, seeing if they are equal
   * @param  {UserAddress} address The address to compare to
   * @return {Boolean}     Whether the addresses are equal
   */
  isEqualTo(address) {
    let isEqual = true;
    this.eachAttribute(name => {
      if (this.nonAddressProperties.includes(name)) {
        return;
      }
      if (this["#{name}"] !== address.get("#{name}")) {
        isEqual = false;
      }
    });
    return isEqual;
  }
});
